html {
	font-family: sans-serif;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%
}

body {
	margin: 0
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
	display: block
}

audio,
canvas,
progress,
video {
	display: inline-block;
	vertical-align: baseline
}

audio:not([controls]) {
	display: none;
	height: 0
}

[hidden],
template {
	display: none
}

a {
	background-color: transparent
}

a:active,
a:hover {
	outline: 0
}

abbr[title] {
	border-bottom: 1px dotted
}

b,
strong {
	font-weight: bold
}

dfn {
	font-style: italic
}

h1 {
	font-size: 2em;
	margin: 0.67em 0
}

mark {
	background: #ff0;
	color: #000
}

small {
	font-size: 80%
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline
}

sup {
	top: -0.5em
}

sub {
	bottom: -0.25em
}

img {
	border: 0
}

svg:not(:root) {
	overflow: hidden
}

figure {
	margin: 1em 40px
}

hr {
	box-sizing: content-box;
	height: 0
}

pre {
	overflow: auto
}

code,
kbd,
pre,
samp {
	font-family: monospace, monospace;
	font-size: 1em
}

button,
input,
optgroup,
select,
textarea {
	color: inherit;
	font: inherit;
	margin: 0
}

button {
	overflow: visible
}

button,
select {
	text-transform: none
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer
}

button[disabled],
html input[disabled] {
	cursor: default
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0
}

input {
	line-height: normal
}

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	height: auto
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em
}

legend {
	border: 0;
	padding: 0
}

textarea {
	overflow: auto
}

optgroup {
	font-weight: bold
}

table {
	border-collapse: collapse;
	border-spacing: 0
}

td,
th {
	padding: 0
}

body,
button,
input,
select,
optgroup,
textarea {
	color: #000;
	font-family: "A1 Sans", sans-serif;
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.5
}

@media (max-width: 581px) {
	body,
	button,
	input,
	select,
	optgroup,
	textarea {
		font-size: 14px
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	clear: both;
	font-weight: 400;
	line-height: 1.2;
	font-family: 'A1 Serif', sans-serif
}

h1 {
	font-size: 48px;
	font-size: 3rem
}

@media (max-width: 899px) {
	h1 {
		font-size: 36px;
		font-size: 2.25rem
	}
}

@media (max-width: 581px) {
	h1 {
		font-size: 28px;
		font-size: 1.75rem
	}
}

h2 {
	font-size: 48px;
	font-size: 3rem
}

@media (max-width: 899px) {
	h2 {
		font-size: 36px;
		font-size: 2.25rem
	}
}

@media (max-width: 581px) {
	h2 {
		font-size: 28px;
		font-size: 1.75rem
	}
}

p {
	margin-bottom: 1.5em
}

dfn,
cite,
em,
i {
	font-style: italic
}

blockquote {
	margin: 0 1.5em
}

address {
	margin: 0 0 1.5em
}

pre {
	background: #eee;
	font-family: "Courier 10 Pitch", Courier, monospace;
	font-size: 15px;
	font-size: .9375rem;
	line-height: 1.6;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em
}

code,
kbd,
tt,
var {
	font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
	font-size: 15px;
	font-size: .9375rem
}

abbr,
acronym {
	border-bottom: 1px dotted #666;
	cursor: help
}

mark,
ins {
	background: #fff9c0;
	text-decoration: none
}

big {
	font-size: 125%
}

@font-face {
	font-family: 'A1 Sans';
	src: url("fonts/A1SansV5-Light.otf") format("opentype"), url("fonts/A1SansV5-Light.ttf") format("truetype"), url("fonts/A1SansV5-Light.woff") format("woff"), url("fonts/A1SansV5-Light.woff2") format("woff");
	font-weight: 100
}

@font-face {
	font-family: 'A1 Sans';
	src: url("fonts/A1SansV5-Regular.otf") format("opentype"), url("fonts/A1SansV5-Regular.ttf") format("truetype"), url("fonts/A1SansV5-Regular.woff") format("woff"), url("fonts/A1SansV5-Regular.woff2") format("woff");
	font-weight: 400
}

@font-face {
	font-family: 'A1 Sans';
	src: url("fonts/A1SansV5-Bold.otf") format("opentype"), url("fonts/A1SansV5-Bold.ttf") format("truetype"), url("fonts/A1SansV5-Bold.woff") format("woff"), url("fonts/A1SansV5-Bold.woff2") format("woff");
	font-weight: 700
}

@font-face {
	font-family: 'A1 Sans Cond';
	src: url("fonts/A1SansCondV5-Light.otf") format("opentype"), url("fonts/A1SansCondV5-Light.ttf") format("truetype"), url("fonts/A1SansCondV5-Light.woff") format("woff"), url("fonts/A1SansCondV5-Light.woff2") format("woff");
	font-weight: 100
}

@font-face {
	font-family: 'A1 Sans Cond';
	src: url("fonts/A1SansCondV5-Regular.otf") format("opentype"), url("fonts/A1SansCondV5-Regular.ttf") format("truetype"), url("fonts/A1SansCondV5-Regular.woff") format("woff"), url("fonts/A1SansCondV5-Regular.woff2") format("woff");
	font-weight: 400
}

@font-face {
	font-family: 'A1 Sans Cond';
	src: url("fonts/A1SansCondV5-Bold.otf") format("opentype"), url("fonts/A1SansCondV5-Bold.ttf") format("truetype"), url("fonts/A1SansCondV5-Bold.woff") format("woff"), url("fonts/A1SansCondV5-Bold.woff2") format("woff");
	font-weight: 700
}

@font-face {
	font-family: 'A1 Serif';
	src: url("fonts/A1SerifV5-Light.otf") format("opentype"), url("fonts/A1SerifV5-Light.ttf") format("truetype"), url("fonts/A1SerifV5-Light.woff") format("woff"), url("fonts/A1SerifV5-Light.woff2") format("woff");
	font-weight: 100
}

@font-face {
	font-family: 'A1 Serif';
	src: url("fonts/A1SerifV5-Regular.otf") format("opentype"), url("fonts/A1SerifV5-Regular.ttf") format("truetype"), url("fonts/A1SerifV5-Regular.woff") format("woff"), url("fonts/A1SerifV5-Regular.woff2") format("woff");
	font-weight: 400
}

@font-face {
	font-family: 'A1 Serif';
	src: url("fonts/A1SerifV5-Bold.otf") format("opentype"), url("fonts/A1SerifV5-Bold.ttf") format("truetype"), url("fonts/A1SerifV5-Bold.woff") format("woff"), url("fonts/A1SerifV5-Bold.woff2") format("woff");
	font-weight: 700
}

.a1now-font-serif {
	font-family: 'A1 Serif'
}

.a1now-font-sanscond {
	font-family: 'A1 Sans Cond'
}

html {
	box-sizing: border-box;
	height: 100%
}

*,
*:before,
*:after {
	box-sizing: inherit
}

body,#root {
	height: 100%;
	background: #fff;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

hr {
	background-color: #ccc;
	border: 0;
	height: 1px;
	margin-bottom: 1.5em
}

ul,
ol {
	margin: 0 0 1.5em 0
}

ul {
	list-style: disc
}

ol {
	list-style: decimal
}

li>ul,
li>ol {
	margin-bottom: 0;
	margin-left: 1.5em
}

dt {
	font-weight: bold
}

dd {
	margin: 0 1.5em 1.5em
}

img {
	height: auto;
	max-width: 100%
}

figure {
	margin: 1em 0
}

table {
	margin: 0 0 1.5em;
	width: 100%
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	padding: 0;
	margin: 0;
	border: none;
	line-height: 1
}

.a1now-btn {
	display: inline-block;
	position: relative;
	padding: 9px 30px;
	background-color: #e42313;
	border: none;
	color: white;
	text-align: center;
	text-decoration: none;
	font-family: "A1 Serif", sans-serif;
	font-size: 26px;
	font-size: 1.625rem;
	-moz-transition: background-color 0.3s ease-out;
	-o-transition: background-color 0.3s ease-out;
	-ms-transition: background-color 0.3s ease-out;
	-webkit-transition: background-color 0.3s ease-out;
	transition: background-color 0.3s ease-out
}

.a1now-btn:hover {
	background-color: #b10000
}

@media (max-width: 581px) {
	.a1now-btn {
		padding: 8px 15px;
		font-size: 20px;
		font-size: 1.25rem
	}
}

.a1now-btn--inactive, .a1now-btn--inactive:hover {
	background-color: #D0D0D0;
	cursor: not-allowed
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
	color: #666;
	border: 1px solid #ccc;
	border-radius: 3px;
	padding: 3px
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
	color: #111
}

select {
	border: 1px solid #ccc
}

textarea {
	width: 100%
}

a:not(.a1now-btn) {
	text-decoration: none;
	color: #e42313;
	-moz-transition: 0.3s ease-out;
	-o-transition: 0.3s ease-out;
	-ms-transition: 0.3s ease-out;
	-webkit-transition: 0.3s ease-out;
	transition: 0.3s ease-out
}

a:not(.a1now-btn):hover {
	color: #b10000
}

.a1now-mainnav {
	position: relative;
	text-align: right
}

.a1now-mainnav__ul {
	padding: 0;
	margin: 0;
	list-style: none;
	font-size: 0
}

.a1now-mainnav__ul>.menu-item {
	display: inline-block;
	padding: 0 25px;
	font-size: 14px;
	font-size: .875rem
}

.a1now-mainnav__ul>.menu-item:last-child {
	margin-right: 0
}

.a1now-mainnav__ul>.menu-item>a {
	display: block;
	position: relative;
	padding: 25px 0 10px;
	color: white;
	font-weight: 700
}

.a1now-mainnav__ul>.menu-item>a>.menu-item__icon {
	display: inline-block;
	width: 22px;
	margin-right: 12px;
	vertical-align: middle;
	margin-top: -2px
}

.a1now-mainnav__indicator {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 4px;
	background: #e42313;
	pointer-events: none;
	-moz-transition: all 0.15s, width 0.15s ease-out;
	-o-transition: all 0.15s, width 0.15s ease-out;
	-ms-transition: all 0.15s, width 0.15s ease-out;
	-webkit-transition: all 0.15s, width 0.15s ease-out;
	transition: all 0.15s, width 0.15s ease-out
}

.a1now-footernav__ul {
	padding: 0;
	margin: 0;
	list-style: none
}

.a1now-footernav__ul>.menu-item {
	display: inline-block;
	position: relative;
	margin-right: 10px
}

.a1now-footernav__ul>.menu-item:after {
	content: '';
	position: absolute;
	top: 0;
	right: -7px;
	display: block;
	width: 1px;
	height: 100%;
	background: grey
}

.a1now-footernav__ul>.menu-item:last-child {
	margin-right: 0
}

.a1now-footernav__ul>.menu-item:last-child:after {
	display: none
}

.a1now-footernav__ul>.menu-item>a {
	color: grey
}

.a1now-footernav__ul>.menu-item:hover>a,
.a1now-footernav__ul>.menu-item>a:focus {
	color: #b10000;
	-moz-transition: color 0.3s ease-out;
	-o-transition: color 0.3s ease-out;
	-ms-transition: color 0.3s ease-out;
	-webkit-transition: color 0.3s ease-out;
	transition: color 0.3s ease-out
}

.a1now-mainnavmobile {
	margin-top: 100px;
	text-align: center
}

@media (max-width: 581px) {
	.a1now-mainnavmobile {
		margin-top: 50px
	}
}

.a1now-mainnavmobile__ul {
	padding: 0;
	margin: 0;
	list-style: none
}

.a1now-mainnavmobile__ul>.menu-item {
	position: relative;
	margin-bottom: 35px
}

.a1now-mainnavmobile__ul>.menu-item>a {
	position: relative;
	padding-right: 1em;
	color: #fff;
	font-weight: 700;
	font-family: 'A1 Serif', sans-serif;
	font-size: 22px;
	font-size: 1.375rem
}

.a1now-mainnavmobile__ul>.menu-item>a:after {
	content: '\e905';
	display: block;
	position: absolute;
	top: .6em;
	right: 0;
	font-family: 'a1now' !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	color: #e42313;
	font-size: 12px;
	font-size: .75rem;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out
}

.a1now-mainnavmobile__ul>.menu-item:hover a:after,
.a1now-mainnavmobile__ul>.menu-item a:focus:after {
	color: #b10000
}

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	word-wrap: normal !important
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: .875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000
}

#content[tabindex="-1"]:focus {
	outline: 0
}

.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto
}

.clear:before:after,
.clear:after:after,
.entry-content:before:after,
.entry-content:after:after,
.comment-content:before:after,
.comment-content:after:after,
.site-header:before:after,
.site-header:after:after,
.site-content:before:after,
.site-content:after:after,
.site-footer:before:after,
.site-footer:after:after {
	content: "";
	display: table;
	clear: both
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
	clear: both
}

.widget {
	margin: 0 0 1.5em
}

.widget select {
	max-width: 100%
}

.sticky {
	display: block
}

.updated:not(.published) {
	display: none
}

.comment-content a {
	word-wrap: break-word
}

.bypostauthor {
	display: block
}

.infinite-scroll .posts-navigation,
.infinite-scroll.neverending .site-footer {
	display: none
}

.infinity-end.neverending .site-footer {
	display: block
}

.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
	border: none;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0
}

embed,
iframe,
object {
	max-width: 100%
}

.custom-logo-link {
	display: inline-block
}

.wp-caption {
	margin-bottom: 1.5em;
	max-width: 100%
}

.wp-caption img[class*="wp-image-"] {
	display: block;
	margin-left: auto;
	margin-right: auto
}

.wp-caption .wp-caption-text {
	margin: 0.8075em 0
}

.wp-caption-text {
	text-align: center
}

.gallery {
	margin-bottom: 1.5em
}

.gallery-item {
	display: inline-block;
	text-align: center;
	vertical-align: top;
	width: 100%
}

.gallery-columns-2 .gallery-item {
	max-width: 50%
}

.gallery-columns-3 .gallery-item {
	max-width: 33.33%
}

.gallery-columns-4 .gallery-item {
	max-width: 25%
}

.gallery-columns-5 .gallery-item {
	max-width: 20%
}

.gallery-columns-6 .gallery-item {
	max-width: 16.66%
}

.gallery-columns-7 .gallery-item {
	max-width: 14.28%
}

.gallery-columns-8 .gallery-item {
	max-width: 12.5%
}

.gallery-columns-9 .gallery-item {
	max-width: 11.11%
}

.gallery-caption {
	display: block
}

.container {
	width: 100%;
	max-width: 1190px;
	padding: 0 15px;
	margin: 0 auto;
	position: relative
}

.container--small {
	max-width: 930px
}

.row {
	display: flex;
	flex: 0 1 auto;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px
}

.row--gap-no {
	margin-left: 0;
	margin-right: 0
}

.row--gap-xsmall {
	margin-left: -7px;
	margin-right: -7px
}

.row--gap-xsmall .col {
	padding-left: 7px;
	padding-right: 7px
}

.row--gap-small {
	margin-left: -10px;
	margin-right: -10px
}

.row--gap-small .col {
	padding-left: 10px;
	padding-right: 10px
}

.row--gap-default {
	margin-left: -15px;
	margin-right: -15px
}

.row--gap-default .col {
	padding-left: 15px;
	padding-right: 15px
}

.row--gap-medium {
	margin-left: -30px;
	margin-right: -30px
}

.row--gap-medium .col {
	padding-left: 30px;
	padding-right: 30px
}

.row--gap-large {
	margin-left: -45px;
	margin-right: -45px
}

.row--gap-no .col {
	padding-left: 0;
	padding-right: 0
}

.row--gap-large .col {
	padding-left: 45px;
	padding-right: 45px
}

.col {
	flex-grow: 1;
	flex-basis: 0;
	max-width: 100%;
	padding-left: 15px;
	padding-right: 15px
}

.col-auto {
	flex: 0 0 auto;
	width: auto;
	max-width: none
}

.a-i-start {
	-webkit-box-align: start;
	-ms-flex-align: start;
	-webkit-align-items: flex-start;
	-moz-align-items: flex-start;
	align-items: flex-start
}

.a-i-center {
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	align-items: center
}

.a-i-end {
	-webkit-box-align: end;
	-ms-flex-align: end;
	-webkit-align-items: flex-end;
	-moz-align-items: flex-end;
	align-items: flex-end
}

.j-c-start {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	-webkit-justify-content: flex-start;
	-moz-justify-content: flex-start;
	justify-content: flex-start
}

.j-c-center {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	justify-content: center;
	text-align: center
}

.j-c-end {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	-webkit-justify-content: flex-end;
	-moz-justify-content: flex-end;
	justify-content: flex-end;
	text-align: right
}

.j-c-around {
	-ms-flex-pack: distribute;
	-webkit-justify-content: space-around;
	-moz-justify-content: space-around;
	justify-content: space-around
}

.j-c-between {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	-webkit-justify-content: space-between;
	-moz-justify-content: space-between;
	justify-content: space-between
}

.a-s-start {
	-webkit-align-self: flex-start;
	-moz-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start
}

.a-s-center {
	-webkit-align-self: center;
	-moz-align-self: center;
	-ms-flex-item-align: center;
	align-self: center
}

.a-s-end {
	-webkit-align-self: flex-end;
	-moz-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end
}

.col-first {
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-moz-order: -1;
	-ms-flex-order: -1;
	order: -1
}

.col-last {
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-moz-order: 1;
	-ms-flex-order: 1;
	order: 1
}

.col-1 {
	flex-basis: 4.16667%;
	max-width: 4.16667%;
	width: 4.16667%
}

.col-offset-1 {
	margin-left: 4.16667%
}

.col-2 {
	flex-basis: 8.33333%;
	max-width: 8.33333%;
	width: 8.33333%
}

.col-offset-2 {
	margin-left: 8.33333%
}

.col-3 {
	flex-basis: 12.5%;
	max-width: 12.5%;
	width: 12.5%
}

.col-offset-3 {
	margin-left: 12.5%
}

.col-4 {
	flex-basis: 16.66667%;
	max-width: 16.66667%;
	width: 16.66667%
}

.col-offset-4 {
	margin-left: 16.66667%
}

.col-5 {
	flex-basis: 20.83333%;
	max-width: 20.83333%;
	width: 20.83333%
}

.col-offset-5 {
	margin-left: 20.83333%
}

.col-6 {
	flex-basis: 25%;
	max-width: 25%;
	width: 25%
}

.col-offset-6 {
	margin-left: 25%
}

.col-7 {
	flex-basis: 29.16667%;
	max-width: 29.16667%;
	width: 29.16667%
}

.col-offset-7 {
	margin-left: 29.16667%
}

.col-8 {
	flex-basis: 33.33333%;
	max-width: 33.33333%;
	width: 33.33333%
}

.col-offset-8 {
	margin-left: 33.33333%
}

.col-9 {
	flex-basis: 37.5%;
	max-width: 37.5%;
	width: 37.5%
}

.col-offset-9 {
	margin-left: 37.5%
}

.col-10 {
	flex-basis: 41.66667%;
	max-width: 41.66667%;
	width: 41.66667%
}

.col-offset-10 {
	margin-left: 41.66667%
}

.col-11 {
	flex-basis: 45.83333%;
	max-width: 45.83333%;
	width: 45.83333%
}

.col-offset-11 {
	margin-left: 45.83333%
}

.col-12 {
	flex-basis: 50%;
	max-width: 50%;
	width: 50%
}

.col-offset-12 {
	margin-left: 50%
}

.col-13 {
	flex-basis: 54.16667%;
	max-width: 54.16667%;
	width: 54.16667%
}

.col-offset-13 {
	margin-left: 54.16667%
}

.col-14 {
	flex-basis: 58.33333%;
	max-width: 58.33333%;
	width: 58.33333%
}

.col-offset-14 {
	margin-left: 58.33333%
}

.col-15 {
	flex-basis: 62.5%;
	max-width: 62.5%;
	width: 62.5%
}

.col-offset-15 {
	margin-left: 62.5%
}

.col-16 {
	flex-basis: 66.66667%;
	max-width: 66.66667%;
	width: 66.66667%
}

.col-offset-16 {
	margin-left: 66.66667%
}

.col-17 {
	flex-basis: 70.83333%;
	max-width: 70.83333%;
	width: 70.83333%
}

.col-offset-17 {
	margin-left: 70.83333%
}

.col-18 {
	flex-basis: 75%;
	max-width: 75%;
	width: 75%
}

.col-offset-18 {
	margin-left: 75%
}

.col-19 {
	flex-basis: 79.16667%;
	max-width: 79.16667%;
	width: 79.16667%
}

.col-offset-19 {
	margin-left: 79.16667%
}

.col-20 {
	flex-basis: 83.33333%;
	max-width: 83.33333%;
	width: 83.33333%
}

.col-offset-20 {
	margin-left: 83.33333%
}

.col-21 {
	flex-basis: 87.5%;
	max-width: 87.5%;
	width: 87.5%
}

.col-offset-21 {
	margin-left: 87.5%
}

.col-22 {
	flex-basis: 91.66667%;
	max-width: 91.66667%;
	width: 91.66667%
}

.col-offset-22 {
	margin-left: 91.66667%
}

.col-23 {
	flex-basis: 95.83333%;
	max-width: 95.83333%;
	width: 95.83333%
}

.col-offset-23 {
	margin-left: 95.83333%
}

.col-24 {
	flex-basis: 100%;
	max-width: 100%;
	width: 100%
}

.col-offset-24 {
	margin-left: 100%
}

@media (max-width: 1279px) {
	.col-xxxl-1 {
		flex-basis: 4.16667%;
		max-width: 4.16667%;
		width: 4.16667%
	}
	.col-offset-xxxl-1 {
		margin-left: 4.16667%
	}
	.col-xxxl-2 {
		flex-basis: 8.33333%;
		max-width: 8.33333%;
		width: 8.33333%
	}
	.col-offset-xxxl-2 {
		margin-left: 8.33333%
	}
	.col-xxxl-3 {
		flex-basis: 12.5%;
		max-width: 12.5%;
		width: 12.5%
	}
	.col-offset-xxxl-3 {
		margin-left: 12.5%
	}
	.col-xxxl-4 {
		flex-basis: 16.66667%;
		max-width: 16.66667%;
		width: 16.66667%
	}
	.col-offset-xxxl-4 {
		margin-left: 16.66667%
	}
	.col-xxxl-5 {
		flex-basis: 20.83333%;
		max-width: 20.83333%;
		width: 20.83333%
	}
	.col-offset-xxxl-5 {
		margin-left: 20.83333%
	}
	.col-xxxl-6 {
		flex-basis: 25%;
		max-width: 25%;
		width: 25%
	}
	.col-offset-xxxl-6 {
		margin-left: 25%
	}
	.col-xxxl-7 {
		flex-basis: 29.16667%;
		max-width: 29.16667%;
		width: 29.16667%
	}
	.col-offset-xxxl-7 {
		margin-left: 29.16667%
	}
	.col-xxxl-8 {
		flex-basis: 33.33333%;
		max-width: 33.33333%;
		width: 33.33333%
	}
	.col-offset-xxxl-8 {
		margin-left: 33.33333%
	}
	.col-xxxl-9 {
		flex-basis: 37.5%;
		max-width: 37.5%;
		width: 37.5%
	}
	.col-offset-xxxl-9 {
		margin-left: 37.5%
	}
	.col-xxxl-10 {
		flex-basis: 41.66667%;
		max-width: 41.66667%;
		width: 41.66667%
	}
	.col-offset-xxxl-10 {
		margin-left: 41.66667%
	}
	.col-xxxl-11 {
		flex-basis: 45.83333%;
		max-width: 45.83333%;
		width: 45.83333%
	}
	.col-offset-xxxl-11 {
		margin-left: 45.83333%
	}
	.col-xxxl-12 {
		flex-basis: 50%;
		max-width: 50%;
		width: 50%
	}
	.col-offset-xxxl-12 {
		margin-left: 50%
	}
	.col-xxxl-13 {
		flex-basis: 54.16667%;
		max-width: 54.16667%;
		width: 54.16667%
	}
	.col-offset-xxxl-13 {
		margin-left: 54.16667%
	}
	.col-xxxl-14 {
		flex-basis: 58.33333%;
		max-width: 58.33333%;
		width: 58.33333%
	}
	.col-offset-xxxl-14 {
		margin-left: 58.33333%
	}
	.col-xxxl-15 {
		flex-basis: 62.5%;
		max-width: 62.5%;
		width: 62.5%
	}
	.col-offset-xxxl-15 {
		margin-left: 62.5%
	}
	.col-xxxl-16 {
		flex-basis: 66.66667%;
		max-width: 66.66667%;
		width: 66.66667%
	}
	.col-offset-xxxl-16 {
		margin-left: 66.66667%
	}
	.col-xxxl-17 {
		flex-basis: 70.83333%;
		max-width: 70.83333%;
		width: 70.83333%
	}
	.col-offset-xxxl-17 {
		margin-left: 70.83333%
	}
	.col-xxxl-18 {
		flex-basis: 75%;
		max-width: 75%;
		width: 75%
	}
	.col-offset-xxxl-18 {
		margin-left: 75%
	}
	.col-xxxl-19 {
		flex-basis: 79.16667%;
		max-width: 79.16667%;
		width: 79.16667%
	}
	.col-offset-xxxl-19 {
		margin-left: 79.16667%
	}
	.col-xxxl-20 {
		flex-basis: 83.33333%;
		max-width: 83.33333%;
		width: 83.33333%
	}
	.col-offset-xxxl-20 {
		margin-left: 83.33333%
	}
	.col-xxxl-21 {
		flex-basis: 87.5%;
		max-width: 87.5%;
		width: 87.5%
	}
	.col-offset-xxxl-21 {
		margin-left: 87.5%
	}
	.col-xxxl-22 {
		flex-basis: 91.66667%;
		max-width: 91.66667%;
		width: 91.66667%
	}
	.col-offset-xxxl-22 {
		margin-left: 91.66667%
	}
	.col-xxxl-23 {
		flex-basis: 95.83333%;
		max-width: 95.83333%;
		width: 95.83333%
	}
	.col-offset-xxxl-23 {
		margin-left: 95.83333%
	}
	.col-xxxl-24 {
		flex-basis: 100%;
		max-width: 100%;
		width: 100%
	}
	.col-offset-xxxl-24 {
		margin-left: 100%
	}
	.a-i-start-xxxl {
		-webkit-box-align: start;
		-ms-flex-align: start;
		-webkit-align-items: flex-start;
		-moz-align-items: flex-start;
		align-items: flex-start
	}
	.a-i-center-xxxl {
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		-moz-align-items: center;
		align-items: center
	}
	.a-i-end-xxxl {
		-webkit-box-align: end;
		-ms-flex-align: end;
		-webkit-align-items: flex-end;
		-moz-align-items: flex-end;
		align-items: flex-end
	}
	.j-c-start-xxxl {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		-webkit-justify-content: flex-start;
		-moz-justify-content: flex-start;
		justify-content: flex-start
	}
	.j-c-center-xxxl {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		-moz-justify-content: center;
		justify-content: center;
		text-align: center
	}
	.j-c-end-xxxl {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		-webkit-justify-content: flex-end;
		-moz-justify-content: flex-end;
		justify-content: flex-end;
		text-align: right
	}
	.j-c-around-xxxl {
		-ms-flex-pack: distribute;
		-webkit-justify-content: space-around;
		-moz-justify-content: space-around;
		justify-content: space-around
	}
	.j-c-between-xxxl {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		-webkit-justify-content: space-between;
		-moz-justify-content: space-between;
		justify-content: space-between
	}
	.a-s-start-xxxl {
		-webkit-align-self: flex-start;
		-moz-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start
	}
	.a-s-center-xxxl {
		-webkit-align-self: center;
		-moz-align-self: center;
		-ms-flex-item-align: center;
		align-self: center
	}
	.a-s-end-xxxl {
		-webkit-align-self: flex-end;
		-moz-align-self: flex-end;
		-ms-flex-item-align: end;
		align-self: flex-end
	}
	.col-first-xxxl {
		order: -1;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-moz-order: -1;
		-ms-flex-order: -1;
		order: -1
	}
	.col-last-xxxl {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-moz-order: 1;
		-ms-flex-order: 1;
		order: 1
	}
	.row--gap-no-xxxl {
		margin-left: 0;
		margin-right: 0
	}
	.row--gap-xsmall-xxxl {
		margin-left: -7px;
		margin-right: -7px
	}
	.row--gap-xsmall-xxxl .col {
		padding-left: 7px;
		padding-right: 7px
	}
	.row--gap-default-xxxl {
		margin-left: -15px;
		margin-right: -15px
	}
	.row--gap-default-xxxl .col {
		padding-left: 15px;
		padding-right: 15px
	}
	.row--gap-medium-xxxl {
		margin-left: -30px;
		margin-right: -30px
	}
	.row--gap-medium-xxxl .col {
		padding-left: 30px;
		padding-right: 30px
	}
	.row--gap-large-xxxl {
		margin-left: -45px;
		margin-right: -45px
	}
	.row--gap-no-xxxl .col {
		padding-left: 0;
		padding-right: 0
	}
	.row--gap-large-xxxl .col {
		padding-left: 45px;
		padding-right: 45px
	}
}

@media (max-width: 1199px) {
	.col-xxl-1 {
		flex-basis: 4.16667%;
		max-width: 4.16667%;
		width: 4.16667%
	}
	.col-offset-xxl-1 {
		margin-left: 4.16667%
	}
	.col-xxl-2 {
		flex-basis: 8.33333%;
		max-width: 8.33333%;
		width: 8.33333%
	}
	.col-offset-xxl-2 {
		margin-left: 8.33333%
	}
	.col-xxl-3 {
		flex-basis: 12.5%;
		max-width: 12.5%;
		width: 12.5%
	}
	.col-offset-xxl-3 {
		margin-left: 12.5%
	}
	.col-xxl-4 {
		flex-basis: 16.66667%;
		max-width: 16.66667%;
		width: 16.66667%
	}
	.col-offset-xxl-4 {
		margin-left: 16.66667%
	}
	.col-xxl-5 {
		flex-basis: 20.83333%;
		max-width: 20.83333%;
		width: 20.83333%
	}
	.col-offset-xxl-5 {
		margin-left: 20.83333%
	}
	.col-xxl-6 {
		flex-basis: 25%;
		max-width: 25%;
		width: 25%
	}
	.col-offset-xxl-6 {
		margin-left: 25%
	}
	.col-xxl-7 {
		flex-basis: 29.16667%;
		max-width: 29.16667%;
		width: 29.16667%
	}
	.col-offset-xxl-7 {
		margin-left: 29.16667%
	}
	.col-xxl-8 {
		flex-basis: 33.33333%;
		max-width: 33.33333%;
		width: 33.33333%
	}
	.col-offset-xxl-8 {
		margin-left: 33.33333%
	}
	.col-xxl-9 {
		flex-basis: 37.5%;
		max-width: 37.5%;
		width: 37.5%
	}
	.col-offset-xxl-9 {
		margin-left: 37.5%
	}
	.col-xxl-10 {
		flex-basis: 41.66667%;
		max-width: 41.66667%;
		width: 41.66667%
	}
	.col-offset-xxl-10 {
		margin-left: 41.66667%
	}
	.col-xxl-11 {
		flex-basis: 45.83333%;
		max-width: 45.83333%;
		width: 45.83333%
	}
	.col-offset-xxl-11 {
		margin-left: 45.83333%
	}
	.col-xxl-12 {
		flex-basis: 50%;
		max-width: 50%;
		width: 50%
	}
	.col-offset-xxl-12 {
		margin-left: 50%
	}
	.col-xxl-13 {
		flex-basis: 54.16667%;
		max-width: 54.16667%;
		width: 54.16667%
	}
	.col-offset-xxl-13 {
		margin-left: 54.16667%
	}
	.col-xxl-14 {
		flex-basis: 58.33333%;
		max-width: 58.33333%;
		width: 58.33333%
	}
	.col-offset-xxl-14 {
		margin-left: 58.33333%
	}
	.col-xxl-15 {
		flex-basis: 62.5%;
		max-width: 62.5%;
		width: 62.5%
	}
	.col-offset-xxl-15 {
		margin-left: 62.5%
	}
	.col-xxl-16 {
		flex-basis: 66.66667%;
		max-width: 66.66667%;
		width: 66.66667%
	}
	.col-offset-xxl-16 {
		margin-left: 66.66667%
	}
	.col-xxl-17 {
		flex-basis: 70.83333%;
		max-width: 70.83333%;
		width: 70.83333%
	}
	.col-offset-xxl-17 {
		margin-left: 70.83333%
	}
	.col-xxl-18 {
		flex-basis: 75%;
		max-width: 75%;
		width: 75%
	}
	.col-offset-xxl-18 {
		margin-left: 75%
	}
	.col-xxl-19 {
		flex-basis: 79.16667%;
		max-width: 79.16667%;
		width: 79.16667%
	}
	.col-offset-xxl-19 {
		margin-left: 79.16667%
	}
	.col-xxl-20 {
		flex-basis: 83.33333%;
		max-width: 83.33333%;
		width: 83.33333%
	}
	.col-offset-xxl-20 {
		margin-left: 83.33333%
	}
	.col-xxl-21 {
		flex-basis: 87.5%;
		max-width: 87.5%;
		width: 87.5%
	}
	.col-offset-xxl-21 {
		margin-left: 87.5%
	}
	.col-xxl-22 {
		flex-basis: 91.66667%;
		max-width: 91.66667%;
		width: 91.66667%
	}
	.col-offset-xxl-22 {
		margin-left: 91.66667%
	}
	.col-xxl-23 {
		flex-basis: 95.83333%;
		max-width: 95.83333%;
		width: 95.83333%
	}
	.col-offset-xxl-23 {
		margin-left: 95.83333%
	}
	.col-xxl-24 {
		flex-basis: 100%;
		max-width: 100%;
		width: 100%
	}
	.col-offset-xxl-24 {
		margin-left: 100%
	}
	.a-i-start-xxl {
		-webkit-box-align: start;
		-ms-flex-align: start;
		-webkit-align-items: flex-start;
		-moz-align-items: flex-start;
		align-items: flex-start
	}
	.a-i-center-xxl {
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		-moz-align-items: center;
		align-items: center
	}
	.a-i-end-xxl {
		-webkit-box-align: end;
		-ms-flex-align: end;
		-webkit-align-items: flex-end;
		-moz-align-items: flex-end;
		align-items: flex-end
	}
	.j-c-start-xxl {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		-webkit-justify-content: flex-start;
		-moz-justify-content: flex-start;
		justify-content: flex-start
	}
	.j-c-center-xxl {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		-moz-justify-content: center;
		justify-content: center;
		text-align: center
	}
	.j-c-end-xxl {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		-webkit-justify-content: flex-end;
		-moz-justify-content: flex-end;
		justify-content: flex-end;
		text-align: right
	}
	.j-c-around-xxl {
		-ms-flex-pack: distribute;
		-webkit-justify-content: space-around;
		-moz-justify-content: space-around;
		justify-content: space-around
	}
	.j-c-between-xxl {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		-webkit-justify-content: space-between;
		-moz-justify-content: space-between;
		justify-content: space-between
	}
	.a-s-start-xxl {
		-webkit-align-self: flex-start;
		-moz-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start
	}
	.a-s-center-xxl {
		-webkit-align-self: center;
		-moz-align-self: center;
		-ms-flex-item-align: center;
		align-self: center
	}
	.a-s-end-xxl {
		-webkit-align-self: flex-end;
		-moz-align-self: flex-end;
		-ms-flex-item-align: end;
		align-self: flex-end
	}
	.col-first-xxl {
		order: -1;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-moz-order: -1;
		-ms-flex-order: -1;
		order: -1
	}
	.col-last-xxl {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-moz-order: 1;
		-ms-flex-order: 1;
		order: 1
	}
	.row--gap-no-xxl {
		margin-left: 0;
		margin-right: 0
	}
	.row--gap-xsmall-xxl {
		margin-left: -7px;
		margin-right: -7px
	}
	.row--gap-xsmall-xxl .col {
		padding-left: 7px;
		padding-right: 7px
	}
	.row--gap-default-xxl {
		margin-left: -15px;
		margin-right: -15px
	}
	.row--gap-default-xxl .col {
		padding-left: 15px;
		padding-right: 15px
	}
	.row--gap-medium-xxl {
		margin-left: -30px;
		margin-right: -30px
	}
	.row--gap-medium-xxl .col {
		padding-left: 30px;
		padding-right: 30px
	}
	.row--gap-large-xxl {
		margin-left: -45px;
		margin-right: -45px
	}
	.row--gap-no-xxl .col {
		padding-left: 0;
		padding-right: 0
	}
	.row--gap-large-xxl .col {
		padding-left: 45px;
		padding-right: 45px
	}
}

@media (max-width: 1023px) {
	.col-xl-1 {
		flex-basis: 4.16667%;
		max-width: 4.16667%;
		width: 4.16667%
	}
	.col-offset-xl-1 {
		margin-left: 4.16667%
	}
	.col-xl-2 {
		flex-basis: 8.33333%;
		max-width: 8.33333%;
		width: 8.33333%
	}
	.col-offset-xl-2 {
		margin-left: 8.33333%
	}
	.col-xl-3 {
		flex-basis: 12.5%;
		max-width: 12.5%;
		width: 12.5%
	}
	.col-offset-xl-3 {
		margin-left: 12.5%
	}
	.col-xl-4 {
		flex-basis: 16.66667%;
		max-width: 16.66667%;
		width: 16.66667%
	}
	.col-offset-xl-4 {
		margin-left: 16.66667%
	}
	.col-xl-5 {
		flex-basis: 20.83333%;
		max-width: 20.83333%;
		width: 20.83333%
	}
	.col-offset-xl-5 {
		margin-left: 20.83333%
	}
	.col-xl-6 {
		flex-basis: 25%;
		max-width: 25%;
		width: 25%
	}
	.col-offset-xl-6 {
		margin-left: 25%
	}
	.col-xl-7 {
		flex-basis: 29.16667%;
		max-width: 29.16667%;
		width: 29.16667%
	}
	.col-offset-xl-7 {
		margin-left: 29.16667%
	}
	.col-xl-8 {
		flex-basis: 33.33333%;
		max-width: 33.33333%;
		width: 33.33333%
	}
	.col-offset-xl-8 {
		margin-left: 33.33333%
	}
	.col-xl-9 {
		flex-basis: 37.5%;
		max-width: 37.5%;
		width: 37.5%
	}
	.col-offset-xl-9 {
		margin-left: 37.5%
	}
	.col-xl-10 {
		flex-basis: 41.66667%;
		max-width: 41.66667%;
		width: 41.66667%
	}
	.col-offset-xl-10 {
		margin-left: 41.66667%
	}
	.col-xl-11 {
		flex-basis: 45.83333%;
		max-width: 45.83333%;
		width: 45.83333%
	}
	.col-offset-xl-11 {
		margin-left: 45.83333%
	}
	.col-xl-12 {
		flex-basis: 50%;
		max-width: 50%;
		width: 50%
	}
	.col-offset-xl-12 {
		margin-left: 50%
	}
	.col-xl-13 {
		flex-basis: 54.16667%;
		max-width: 54.16667%;
		width: 54.16667%
	}
	.col-offset-xl-13 {
		margin-left: 54.16667%
	}
	.col-xl-14 {
		flex-basis: 58.33333%;
		max-width: 58.33333%;
		width: 58.33333%
	}
	.col-offset-xl-14 {
		margin-left: 58.33333%
	}
	.col-xl-15 {
		flex-basis: 62.5%;
		max-width: 62.5%;
		width: 62.5%
	}
	.col-offset-xl-15 {
		margin-left: 62.5%
	}
	.col-xl-16 {
		flex-basis: 66.66667%;
		max-width: 66.66667%;
		width: 66.66667%
	}
	.col-offset-xl-16 {
		margin-left: 66.66667%
	}
	.col-xl-17 {
		flex-basis: 70.83333%;
		max-width: 70.83333%;
		width: 70.83333%
	}
	.col-offset-xl-17 {
		margin-left: 70.83333%
	}
	.col-xl-18 {
		flex-basis: 75%;
		max-width: 75%;
		width: 75%
	}
	.col-offset-xl-18 {
		margin-left: 75%
	}
	.col-xl-19 {
		flex-basis: 79.16667%;
		max-width: 79.16667%;
		width: 79.16667%
	}
	.col-offset-xl-19 {
		margin-left: 79.16667%
	}
	.col-xl-20 {
		flex-basis: 83.33333%;
		max-width: 83.33333%;
		width: 83.33333%
	}
	.col-offset-xl-20 {
		margin-left: 83.33333%
	}
	.col-xl-21 {
		flex-basis: 87.5%;
		max-width: 87.5%;
		width: 87.5%
	}
	.col-offset-xl-21 {
		margin-left: 87.5%
	}
	.col-xl-22 {
		flex-basis: 91.66667%;
		max-width: 91.66667%;
		width: 91.66667%
	}
	.col-offset-xl-22 {
		margin-left: 91.66667%
	}
	.col-xl-23 {
		flex-basis: 95.83333%;
		max-width: 95.83333%;
		width: 95.83333%
	}
	.col-offset-xl-23 {
		margin-left: 95.83333%
	}
	.col-xl-24 {
		flex-basis: 100%;
		max-width: 100%;
		width: 100%
	}
	.col-offset-xl-24 {
		margin-left: 100%
	}
	.a-i-start-xl {
		-webkit-box-align: start;
		-ms-flex-align: start;
		-webkit-align-items: flex-start;
		-moz-align-items: flex-start;
		align-items: flex-start
	}
	.a-i-center-xl {
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		-moz-align-items: center;
		align-items: center
	}
	.a-i-end-xl {
		-webkit-box-align: end;
		-ms-flex-align: end;
		-webkit-align-items: flex-end;
		-moz-align-items: flex-end;
		align-items: flex-end
	}
	.j-c-start-xl {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		-webkit-justify-content: flex-start;
		-moz-justify-content: flex-start;
		justify-content: flex-start
	}
	.j-c-center-xl {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		-moz-justify-content: center;
		justify-content: center;
		text-align: center
	}
	.j-c-end-xl {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		-webkit-justify-content: flex-end;
		-moz-justify-content: flex-end;
		justify-content: flex-end;
		text-align: right
	}
	.j-c-around-xl {
		-ms-flex-pack: distribute;
		-webkit-justify-content: space-around;
		-moz-justify-content: space-around;
		justify-content: space-around
	}
	.j-c-between-xl {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		-webkit-justify-content: space-between;
		-moz-justify-content: space-between;
		justify-content: space-between
	}
	.a-s-start-xl {
		-webkit-align-self: flex-start;
		-moz-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start
	}
	.a-s-center-xl {
		-webkit-align-self: center;
		-moz-align-self: center;
		-ms-flex-item-align: center;
		align-self: center
	}
	.a-s-end-xl {
		-webkit-align-self: flex-end;
		-moz-align-self: flex-end;
		-ms-flex-item-align: end;
		align-self: flex-end
	}
	.col-first-xl {
		order: -1;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-moz-order: -1;
		-ms-flex-order: -1;
		order: -1
	}
	.col-last-xl {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-moz-order: 1;
		-ms-flex-order: 1;
		order: 1
	}
	.row--gap-no-xl {
		margin-left: 0;
		margin-right: 0
	}
	.row--gap-xsmall-xl {
		margin-left: -7px;
		margin-right: -7px
	}
	.row--gap-xsmall-xl .col {
		padding-left: 7px;
		padding-right: 7px
	}
	.row--gap-default-xl {
		margin-left: -15px;
		margin-right: -15px
	}
	.row--gap-default-xl .col {
		padding-left: 15px;
		padding-right: 15px
	}
	.row--gap-medium-xl {
		margin-left: -30px;
		margin-right: -30px
	}
	.row--gap-medium-xl .col {
		padding-left: 30px;
		padding-right: 30px
	}
	.row--gap-large-xl {
		margin-left: -45px;
		margin-right: -45px
	}
	.row--gap-no-xl .col {
		padding-left: 0;
		padding-right: 0
	}
	.row--gap-large-xl .col {
		padding-left: 45px;
		padding-right: 45px
	}
}

@media (max-width: 899px) {
	.col-l-1 {
		flex-basis: 4.16667%;
		max-width: 4.16667%;
		width: 4.16667%
	}
	.col-offset-l-1 {
		margin-left: 4.16667%
	}
	.col-l-2 {
		flex-basis: 8.33333%;
		max-width: 8.33333%;
		width: 8.33333%
	}
	.col-offset-l-2 {
		margin-left: 8.33333%
	}
	.col-l-3 {
		flex-basis: 12.5%;
		max-width: 12.5%;
		width: 12.5%
	}
	.col-offset-l-3 {
		margin-left: 12.5%
	}
	.col-l-4 {
		flex-basis: 16.66667%;
		max-width: 16.66667%;
		width: 16.66667%
	}
	.col-offset-l-4 {
		margin-left: 16.66667%
	}
	.col-l-5 {
		flex-basis: 20.83333%;
		max-width: 20.83333%;
		width: 20.83333%
	}
	.col-offset-l-5 {
		margin-left: 20.83333%
	}
	.col-l-6 {
		flex-basis: 25%;
		max-width: 25%;
		width: 25%
	}
	.col-offset-l-6 {
		margin-left: 25%
	}
	.col-l-7 {
		flex-basis: 29.16667%;
		max-width: 29.16667%;
		width: 29.16667%
	}
	.col-offset-l-7 {
		margin-left: 29.16667%
	}
	.col-l-8 {
		flex-basis: 33.33333%;
		max-width: 33.33333%;
		width: 33.33333%
	}
	.col-offset-l-8 {
		margin-left: 33.33333%
	}
	.col-l-9 {
		flex-basis: 37.5%;
		max-width: 37.5%;
		width: 37.5%
	}
	.col-offset-l-9 {
		margin-left: 37.5%
	}
	.col-l-10 {
		flex-basis: 41.66667%;
		max-width: 41.66667%;
		width: 41.66667%
	}
	.col-offset-l-10 {
		margin-left: 41.66667%
	}
	.col-l-11 {
		flex-basis: 45.83333%;
		max-width: 45.83333%;
		width: 45.83333%
	}
	.col-offset-l-11 {
		margin-left: 45.83333%
	}
	.col-l-12 {
		flex-basis: 50%;
		max-width: 50%;
		width: 50%
	}
	.col-offset-l-12 {
		margin-left: 50%
	}
	.col-l-13 {
		flex-basis: 54.16667%;
		max-width: 54.16667%;
		width: 54.16667%
	}
	.col-offset-l-13 {
		margin-left: 54.16667%
	}
	.col-l-14 {
		flex-basis: 58.33333%;
		max-width: 58.33333%;
		width: 58.33333%
	}
	.col-offset-l-14 {
		margin-left: 58.33333%
	}
	.col-l-15 {
		flex-basis: 62.5%;
		max-width: 62.5%;
		width: 62.5%
	}
	.col-offset-l-15 {
		margin-left: 62.5%
	}
	.col-l-16 {
		flex-basis: 66.66667%;
		max-width: 66.66667%;
		width: 66.66667%
	}
	.col-offset-l-16 {
		margin-left: 66.66667%
	}
	.col-l-17 {
		flex-basis: 70.83333%;
		max-width: 70.83333%;
		width: 70.83333%
	}
	.col-offset-l-17 {
		margin-left: 70.83333%
	}
	.col-l-18 {
		flex-basis: 75%;
		max-width: 75%;
		width: 75%
	}
	.col-offset-l-18 {
		margin-left: 75%
	}
	.col-l-19 {
		flex-basis: 79.16667%;
		max-width: 79.16667%;
		width: 79.16667%
	}
	.col-offset-l-19 {
		margin-left: 79.16667%
	}
	.col-l-20 {
		flex-basis: 83.33333%;
		max-width: 83.33333%;
		width: 83.33333%
	}
	.col-offset-l-20 {
		margin-left: 83.33333%
	}
	.col-l-21 {
		flex-basis: 87.5%;
		max-width: 87.5%;
		width: 87.5%
	}
	.col-offset-l-21 {
		margin-left: 87.5%
	}
	.col-l-22 {
		flex-basis: 91.66667%;
		max-width: 91.66667%;
		width: 91.66667%
	}
	.col-offset-l-22 {
		margin-left: 91.66667%
	}
	.col-l-23 {
		flex-basis: 95.83333%;
		max-width: 95.83333%;
		width: 95.83333%
	}
	.col-offset-l-23 {
		margin-left: 95.83333%
	}
	.col-l-24 {
		flex-basis: 100%;
		max-width: 100%;
		width: 100%
	}
	.col-offset-l-24 {
		margin-left: 100%
	}
	.a-i-start-l {
		-webkit-box-align: start;
		-ms-flex-align: start;
		-webkit-align-items: flex-start;
		-moz-align-items: flex-start;
		align-items: flex-start
	}
	.a-i-center-l {
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		-moz-align-items: center;
		align-items: center
	}
	.a-i-end-l {
		-webkit-box-align: end;
		-ms-flex-align: end;
		-webkit-align-items: flex-end;
		-moz-align-items: flex-end;
		align-items: flex-end
	}
	.j-c-start-l {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		-webkit-justify-content: flex-start;
		-moz-justify-content: flex-start;
		justify-content: flex-start
	}
	.j-c-center-l {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		-moz-justify-content: center;
		justify-content: center;
		text-align: center
	}
	.j-c-end-l {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		-webkit-justify-content: flex-end;
		-moz-justify-content: flex-end;
		justify-content: flex-end;
		text-align: right
	}
	.j-c-around-l {
		-ms-flex-pack: distribute;
		-webkit-justify-content: space-around;
		-moz-justify-content: space-around;
		justify-content: space-around
	}
	.j-c-between-l {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		-webkit-justify-content: space-between;
		-moz-justify-content: space-between;
		justify-content: space-between
	}
	.a-s-start-l {
		-webkit-align-self: flex-start;
		-moz-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start
	}
	.a-s-center-l {
		-webkit-align-self: center;
		-moz-align-self: center;
		-ms-flex-item-align: center;
		align-self: center
	}
	.a-s-end-l {
		-webkit-align-self: flex-end;
		-moz-align-self: flex-end;
		-ms-flex-item-align: end;
		align-self: flex-end
	}
	.col-first-l {
		order: -1;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-moz-order: -1;
		-ms-flex-order: -1;
		order: -1
	}
	.col-last-l {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-moz-order: 1;
		-ms-flex-order: 1;
		order: 1
	}
	.row--gap-no-l {
		margin-left: 0;
		margin-right: 0
	}
	.row--gap-xsmall-l {
		margin-left: -7px;
		margin-right: -7px
	}
	.row--gap-xsmall-l .col {
		padding-left: 7px;
		padding-right: 7px
	}
	.row--gap-default-l {
		margin-left: -15px;
		margin-right: -15px
	}
	.row--gap-default-l .col {
		padding-left: 15px;
		padding-right: 15px
	}
	.row--gap-medium-l {
		margin-left: -30px;
		margin-right: -30px
	}
	.row--gap-medium-l .col {
		padding-left: 30px;
		padding-right: 30px
	}
	.row--gap-large-l {
		margin-left: -45px;
		margin-right: -45px
	}
	.row--gap-no-l .col {
		padding-left: 0;
		padding-right: 0
	}
	.row--gap-large-l .col {
		padding-left: 45px;
		padding-right: 45px
	}
}

@media (max-width: 767px) {
	.col-m-1 {
		flex-basis: 4.16667%;
		max-width: 4.16667%;
		width: 4.16667%
	}
	.col-offset-m-1 {
		margin-left: 4.16667%
	}
	.col-m-2 {
		flex-basis: 8.33333%;
		max-width: 8.33333%;
		width: 8.33333%
	}
	.col-offset-m-2 {
		margin-left: 8.33333%
	}
	.col-m-3 {
		flex-basis: 12.5%;
		max-width: 12.5%;
		width: 12.5%
	}
	.col-offset-m-3 {
		margin-left: 12.5%
	}
	.col-m-4 {
		flex-basis: 16.66667%;
		max-width: 16.66667%;
		width: 16.66667%
	}
	.col-offset-m-4 {
		margin-left: 16.66667%
	}
	.col-m-5 {
		flex-basis: 20.83333%;
		max-width: 20.83333%;
		width: 20.83333%
	}
	.col-offset-m-5 {
		margin-left: 20.83333%
	}
	.col-m-6 {
		flex-basis: 25%;
		max-width: 25%;
		width: 25%
	}
	.col-offset-m-6 {
		margin-left: 25%
	}
	.col-m-7 {
		flex-basis: 29.16667%;
		max-width: 29.16667%;
		width: 29.16667%
	}
	.col-offset-m-7 {
		margin-left: 29.16667%
	}
	.col-m-8 {
		flex-basis: 33.33333%;
		max-width: 33.33333%;
		width: 33.33333%
	}
	.col-offset-m-8 {
		margin-left: 33.33333%
	}
	.col-m-9 {
		flex-basis: 37.5%;
		max-width: 37.5%;
		width: 37.5%
	}
	.col-offset-m-9 {
		margin-left: 37.5%
	}
	.col-m-10 {
		flex-basis: 41.66667%;
		max-width: 41.66667%;
		width: 41.66667%
	}
	.col-offset-m-10 {
		margin-left: 41.66667%
	}
	.col-m-11 {
		flex-basis: 45.83333%;
		max-width: 45.83333%;
		width: 45.83333%
	}
	.col-offset-m-11 {
		margin-left: 45.83333%
	}
	.col-m-12 {
		flex-basis: 50%;
		max-width: 50%;
		width: 50%
	}
	.col-offset-m-12 {
		margin-left: 50%
	}
	.col-m-13 {
		flex-basis: 54.16667%;
		max-width: 54.16667%;
		width: 54.16667%
	}
	.col-offset-m-13 {
		margin-left: 54.16667%
	}
	.col-m-14 {
		flex-basis: 58.33333%;
		max-width: 58.33333%;
		width: 58.33333%
	}
	.col-offset-m-14 {
		margin-left: 58.33333%
	}
	.col-m-15 {
		flex-basis: 62.5%;
		max-width: 62.5%;
		width: 62.5%
	}
	.col-offset-m-15 {
		margin-left: 62.5%
	}
	.col-m-16 {
		flex-basis: 66.66667%;
		max-width: 66.66667%;
		width: 66.66667%
	}
	.col-offset-m-16 {
		margin-left: 66.66667%
	}
	.col-m-17 {
		flex-basis: 70.83333%;
		max-width: 70.83333%;
		width: 70.83333%
	}
	.col-offset-m-17 {
		margin-left: 70.83333%
	}
	.col-m-18 {
		flex-basis: 75%;
		max-width: 75%;
		width: 75%
	}
	.col-offset-m-18 {
		margin-left: 75%
	}
	.col-m-19 {
		flex-basis: 79.16667%;
		max-width: 79.16667%;
		width: 79.16667%
	}
	.col-offset-m-19 {
		margin-left: 79.16667%
	}
	.col-m-20 {
		flex-basis: 83.33333%;
		max-width: 83.33333%;
		width: 83.33333%
	}
	.col-offset-m-20 {
		margin-left: 83.33333%
	}
	.col-m-21 {
		flex-basis: 87.5%;
		max-width: 87.5%;
		width: 87.5%
	}
	.col-offset-m-21 {
		margin-left: 87.5%
	}
	.col-m-22 {
		flex-basis: 91.66667%;
		max-width: 91.66667%;
		width: 91.66667%
	}
	.col-offset-m-22 {
		margin-left: 91.66667%
	}
	.col-m-23 {
		flex-basis: 95.83333%;
		max-width: 95.83333%;
		width: 95.83333%
	}
	.col-offset-m-23 {
		margin-left: 95.83333%
	}
	.col-m-24 {
		flex-basis: 100%;
		max-width: 100%;
		width: 100%
	}
	.col-offset-m-24 {
		margin-left: 100%
	}
	.a-i-start-m {
		-webkit-box-align: start;
		-ms-flex-align: start;
		-webkit-align-items: flex-start;
		-moz-align-items: flex-start;
		align-items: flex-start
	}
	.a-i-center-m {
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		-moz-align-items: center;
		align-items: center
	}
	.a-i-end-m {
		-webkit-box-align: end;
		-ms-flex-align: end;
		-webkit-align-items: flex-end;
		-moz-align-items: flex-end;
		align-items: flex-end
	}
	.j-c-start-m {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		-webkit-justify-content: flex-start;
		-moz-justify-content: flex-start;
		justify-content: flex-start
	}
	.j-c-center-m {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		-moz-justify-content: center;
		justify-content: center;
		text-align: center
	}
	.j-c-end-m {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		-webkit-justify-content: flex-end;
		-moz-justify-content: flex-end;
		justify-content: flex-end;
		text-align: right
	}
	.j-c-around-m {
		-ms-flex-pack: distribute;
		-webkit-justify-content: space-around;
		-moz-justify-content: space-around;
		justify-content: space-around
	}
	.j-c-between-m {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		-webkit-justify-content: space-between;
		-moz-justify-content: space-between;
		justify-content: space-between
	}
	.a-s-start-m {
		-webkit-align-self: flex-start;
		-moz-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start
	}
	.a-s-center-m {
		-webkit-align-self: center;
		-moz-align-self: center;
		-ms-flex-item-align: center;
		align-self: center
	}
	.a-s-end-m {
		-webkit-align-self: flex-end;
		-moz-align-self: flex-end;
		-ms-flex-item-align: end;
		align-self: flex-end
	}
	.col-first-m {
		order: -1;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-moz-order: -1;
		-ms-flex-order: -1;
		order: -1
	}
	.col-last-m {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-moz-order: 1;
		-ms-flex-order: 1;
		order: 1
	}
	.row--gap-no-m {
		margin-left: 0;
		margin-right: 0
	}
	.row--gap-xsmall-m {
		margin-left: -7px;
		margin-right: -7px
	}
	.row--gap-xsmall-m .col {
		padding-left: 7px;
		padding-right: 7px
	}
	.row--gap-default-m {
		margin-left: -15px;
		margin-right: -15px
	}
	.row--gap-default-m .col {
		padding-left: 15px;
		padding-right: 15px
	}
	.row--gap-medium-m {
		margin-left: -30px;
		margin-right: -30px
	}
	.row--gap-medium-m .col {
		padding-left: 30px;
		padding-right: 30px
	}
	.row--gap-large-m {
		margin-left: -45px;
		margin-right: -45px
	}
	.row--gap-no-m .col {
		padding-left: 0;
		padding-right: 0
	}
	.row--gap-large-m .col {
		padding-left: 45px;
		padding-right: 45px
	}
}

@media (max-width: 581px) {
	.col-s-1 {
		flex-basis: 4.16667%;
		max-width: 4.16667%;
		width: 4.16667%
	}
	.col-offset-s-1 {
		margin-left: 4.16667%
	}
	.col-s-2 {
		flex-basis: 8.33333%;
		max-width: 8.33333%;
		width: 8.33333%
	}
	.col-offset-s-2 {
		margin-left: 8.33333%
	}
	.col-s-3 {
		flex-basis: 12.5%;
		max-width: 12.5%;
		width: 12.5%
	}
	.col-offset-s-3 {
		margin-left: 12.5%
	}
	.col-s-4 {
		flex-basis: 16.66667%;
		max-width: 16.66667%;
		width: 16.66667%
	}
	.col-offset-s-4 {
		margin-left: 16.66667%
	}
	.col-s-5 {
		flex-basis: 20.83333%;
		max-width: 20.83333%;
		width: 20.83333%
	}
	.col-offset-s-5 {
		margin-left: 20.83333%
	}
	.col-s-6 {
		flex-basis: 25%;
		max-width: 25%;
		width: 25%
	}
	.col-offset-s-6 {
		margin-left: 25%
	}
	.col-s-7 {
		flex-basis: 29.16667%;
		max-width: 29.16667%;
		width: 29.16667%
	}
	.col-offset-s-7 {
		margin-left: 29.16667%
	}
	.col-s-8 {
		flex-basis: 33.33333%;
		max-width: 33.33333%;
		width: 33.33333%
	}
	.col-offset-s-8 {
		margin-left: 33.33333%
	}
	.col-s-9 {
		flex-basis: 37.5%;
		max-width: 37.5%;
		width: 37.5%
	}
	.col-offset-s-9 {
		margin-left: 37.5%
	}
	.col-s-10 {
		flex-basis: 41.66667%;
		max-width: 41.66667%;
		width: 41.66667%
	}
	.col-offset-s-10 {
		margin-left: 41.66667%
	}
	.col-s-11 {
		flex-basis: 45.83333%;
		max-width: 45.83333%;
		width: 45.83333%
	}
	.col-offset-s-11 {
		margin-left: 45.83333%
	}
	.col-s-12 {
		flex-basis: 50%;
		max-width: 50%;
		width: 50%
	}
	.col-offset-s-12 {
		margin-left: 50%
	}
	.col-s-13 {
		flex-basis: 54.16667%;
		max-width: 54.16667%;
		width: 54.16667%
	}
	.col-offset-s-13 {
		margin-left: 54.16667%
	}
	.col-s-14 {
		flex-basis: 58.33333%;
		max-width: 58.33333%;
		width: 58.33333%
	}
	.col-offset-s-14 {
		margin-left: 58.33333%
	}
	.col-s-15 {
		flex-basis: 62.5%;
		max-width: 62.5%;
		width: 62.5%
	}
	.col-offset-s-15 {
		margin-left: 62.5%
	}
	.col-s-16 {
		flex-basis: 66.66667%;
		max-width: 66.66667%;
		width: 66.66667%
	}
	.col-offset-s-16 {
		margin-left: 66.66667%
	}
	.col-s-17 {
		flex-basis: 70.83333%;
		max-width: 70.83333%;
		width: 70.83333%
	}
	.col-offset-s-17 {
		margin-left: 70.83333%
	}
	.col-s-18 {
		flex-basis: 75%;
		max-width: 75%;
		width: 75%
	}
	.col-offset-s-18 {
		margin-left: 75%
	}
	.col-s-19 {
		flex-basis: 79.16667%;
		max-width: 79.16667%;
		width: 79.16667%
	}
	.col-offset-s-19 {
		margin-left: 79.16667%
	}
	.col-s-20 {
		flex-basis: 83.33333%;
		max-width: 83.33333%;
		width: 83.33333%
	}
	.col-offset-s-20 {
		margin-left: 83.33333%
	}
	.col-s-21 {
		flex-basis: 87.5%;
		max-width: 87.5%;
		width: 87.5%
	}
	.col-offset-s-21 {
		margin-left: 87.5%
	}
	.col-s-22 {
		flex-basis: 91.66667%;
		max-width: 91.66667%;
		width: 91.66667%
	}
	.col-offset-s-22 {
		margin-left: 91.66667%
	}
	.col-s-23 {
		flex-basis: 95.83333%;
		max-width: 95.83333%;
		width: 95.83333%
	}
	.col-offset-s-23 {
		margin-left: 95.83333%
	}
	.col-s-24 {
		flex-basis: 100%;
		max-width: 100%;
		width: 100%
	}
	.col-offset-s-24 {
		margin-left: 100%
	}
	.a-i-start-s {
		-webkit-box-align: start;
		-ms-flex-align: start;
		-webkit-align-items: flex-start;
		-moz-align-items: flex-start;
		align-items: flex-start
	}
	.a-i-center-s {
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		-moz-align-items: center;
		align-items: center
	}
	.a-i-end-s {
		-webkit-box-align: end;
		-ms-flex-align: end;
		-webkit-align-items: flex-end;
		-moz-align-items: flex-end;
		align-items: flex-end
	}
	.j-c-start-s {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		-webkit-justify-content: flex-start;
		-moz-justify-content: flex-start;
		justify-content: flex-start
	}
	.j-c-center-s {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		-moz-justify-content: center;
		justify-content: center;
		text-align: center
	}
	.j-c-end-s {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		-webkit-justify-content: flex-end;
		-moz-justify-content: flex-end;
		justify-content: flex-end;
		text-align: right
	}
	.j-c-around-s {
		-ms-flex-pack: distribute;
		-webkit-justify-content: space-around;
		-moz-justify-content: space-around;
		justify-content: space-around
	}
	.j-c-between-s {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		-webkit-justify-content: space-between;
		-moz-justify-content: space-between;
		justify-content: space-between
	}
	.a-s-start-s {
		-webkit-align-self: flex-start;
		-moz-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start
	}
	.a-s-center-s {
		-webkit-align-self: center;
		-moz-align-self: center;
		-ms-flex-item-align: center;
		align-self: center
	}
	.a-s-end-s {
		-webkit-align-self: flex-end;
		-moz-align-self: flex-end;
		-ms-flex-item-align: end;
		align-self: flex-end
	}
	.col-first-s {
		order: -1;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-moz-order: -1;
		-ms-flex-order: -1;
		order: -1
	}
	.col-last-s {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-moz-order: 1;
		-ms-flex-order: 1;
		order: 1
	}
	.row--gap-no-s {
		margin-left: 0;
		margin-right: 0
	}
	.row--gap-xsmall-s {
		margin-left: -7px;
		margin-right: -7px
	}
	.row--gap-xsmall-s .col {
		padding-left: 7px;
		padding-right: 7px
	}
	.row--gap-default-s {
		margin-left: -15px;
		margin-right: -15px
	}
	.row--gap-default-s .col {
		padding-left: 15px;
		padding-right: 15px
	}
	.row--gap-medium-s {
		margin-left: -30px;
		margin-right: -30px
	}
	.row--gap-medium-s .col {
		padding-left: 30px;
		padding-right: 30px
	}
	.row--gap-large-s {
		margin-left: -45px;
		margin-right: -45px
	}
	.row--gap-no-s .col {
		padding-left: 0;
		padding-right: 0
	}
	.row--gap-large-s .col {
		padding-left: 45px;
		padding-right: 45px
	}
}

@media (max-width: 479px) {
	.col-xs-1 {
		flex-basis: 4.16667%;
		max-width: 4.16667%;
		width: 4.16667%
	}
	.col-offset-xs-1 {
		margin-left: 4.16667%
	}
	.col-xs-2 {
		flex-basis: 8.33333%;
		max-width: 8.33333%;
		width: 8.33333%
	}
	.col-offset-xs-2 {
		margin-left: 8.33333%
	}
	.col-xs-3 {
		flex-basis: 12.5%;
		max-width: 12.5%;
		width: 12.5%
	}
	.col-offset-xs-3 {
		margin-left: 12.5%
	}
	.col-xs-4 {
		flex-basis: 16.66667%;
		max-width: 16.66667%;
		width: 16.66667%
	}
	.col-offset-xs-4 {
		margin-left: 16.66667%
	}
	.col-xs-5 {
		flex-basis: 20.83333%;
		max-width: 20.83333%;
		width: 20.83333%
	}
	.col-offset-xs-5 {
		margin-left: 20.83333%
	}
	.col-xs-6 {
		flex-basis: 25%;
		max-width: 25%;
		width: 25%
	}
	.col-offset-xs-6 {
		margin-left: 25%
	}
	.col-xs-7 {
		flex-basis: 29.16667%;
		max-width: 29.16667%;
		width: 29.16667%
	}
	.col-offset-xs-7 {
		margin-left: 29.16667%
	}
	.col-xs-8 {
		flex-basis: 33.33333%;
		max-width: 33.33333%;
		width: 33.33333%
	}
	.col-offset-xs-8 {
		margin-left: 33.33333%
	}
	.col-xs-9 {
		flex-basis: 37.5%;
		max-width: 37.5%;
		width: 37.5%
	}
	.col-offset-xs-9 {
		margin-left: 37.5%
	}
	.col-xs-10 {
		flex-basis: 41.66667%;
		max-width: 41.66667%;
		width: 41.66667%
	}
	.col-offset-xs-10 {
		margin-left: 41.66667%
	}
	.col-xs-11 {
		flex-basis: 45.83333%;
		max-width: 45.83333%;
		width: 45.83333%
	}
	.col-offset-xs-11 {
		margin-left: 45.83333%
	}
	.col-xs-12 {
		flex-basis: 50%;
		max-width: 50%;
		width: 50%
	}
	.col-offset-xs-12 {
		margin-left: 50%
	}
	.col-xs-13 {
		flex-basis: 54.16667%;
		max-width: 54.16667%;
		width: 54.16667%
	}
	.col-offset-xs-13 {
		margin-left: 54.16667%
	}
	.col-xs-14 {
		flex-basis: 58.33333%;
		max-width: 58.33333%;
		width: 58.33333%
	}
	.col-offset-xs-14 {
		margin-left: 58.33333%
	}
	.col-xs-15 {
		flex-basis: 62.5%;
		max-width: 62.5%;
		width: 62.5%
	}
	.col-offset-xs-15 {
		margin-left: 62.5%
	}
	.col-xs-16 {
		flex-basis: 66.66667%;
		max-width: 66.66667%;
		width: 66.66667%
	}
	.col-offset-xs-16 {
		margin-left: 66.66667%
	}
	.col-xs-17 {
		flex-basis: 70.83333%;
		max-width: 70.83333%;
		width: 70.83333%
	}
	.col-offset-xs-17 {
		margin-left: 70.83333%
	}
	.col-xs-18 {
		flex-basis: 75%;
		max-width: 75%;
		width: 75%
	}
	.col-offset-xs-18 {
		margin-left: 75%
	}
	.col-xs-19 {
		flex-basis: 79.16667%;
		max-width: 79.16667%;
		width: 79.16667%
	}
	.col-offset-xs-19 {
		margin-left: 79.16667%
	}
	.col-xs-20 {
		flex-basis: 83.33333%;
		max-width: 83.33333%;
		width: 83.33333%
	}
	.col-offset-xs-20 {
		margin-left: 83.33333%
	}
	.col-xs-21 {
		flex-basis: 87.5%;
		max-width: 87.5%;
		width: 87.5%
	}
	.col-offset-xs-21 {
		margin-left: 87.5%
	}
	.col-xs-22 {
		flex-basis: 91.66667%;
		max-width: 91.66667%;
		width: 91.66667%
	}
	.col-offset-xs-22 {
		margin-left: 91.66667%
	}
	.col-xs-23 {
		flex-basis: 95.83333%;
		max-width: 95.83333%;
		width: 95.83333%
	}
	.col-offset-xs-23 {
		margin-left: 95.83333%
	}
	.col-xs-24 {
		flex-basis: 100%;
		max-width: 100%;
		width: 100%
	}
	.col-offset-xs-24 {
		margin-left: 100%
	}
	.a-i-start-xs {
		-webkit-box-align: start;
		-ms-flex-align: start;
		-webkit-align-items: flex-start;
		-moz-align-items: flex-start;
		align-items: flex-start
	}
	.a-i-center-xs {
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		-moz-align-items: center;
		align-items: center
	}
	.a-i-end-xs {
		-webkit-box-align: end;
		-ms-flex-align: end;
		-webkit-align-items: flex-end;
		-moz-align-items: flex-end;
		align-items: flex-end
	}
	.j-c-start-xs {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		-webkit-justify-content: flex-start;
		-moz-justify-content: flex-start;
		justify-content: flex-start
	}
	.j-c-center-xs {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		-moz-justify-content: center;
		justify-content: center;
		text-align: center
	}
	.j-c-end-xs {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		-webkit-justify-content: flex-end;
		-moz-justify-content: flex-end;
		justify-content: flex-end;
		text-align: right
	}
	.j-c-around-xs {
		-ms-flex-pack: distribute;
		-webkit-justify-content: space-around;
		-moz-justify-content: space-around;
		justify-content: space-around
	}
	.j-c-between-xs {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		-webkit-justify-content: space-between;
		-moz-justify-content: space-between;
		justify-content: space-between
	}
	.a-s-start-xs {
		-webkit-align-self: flex-start;
		-moz-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start
	}
	.a-s-center-xs {
		-webkit-align-self: center;
		-moz-align-self: center;
		-ms-flex-item-align: center;
		align-self: center
	}
	.a-s-end-xs {
		-webkit-align-self: flex-end;
		-moz-align-self: flex-end;
		-ms-flex-item-align: end;
		align-self: flex-end
	}
	.col-first-xs {
		order: -1;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-moz-order: -1;
		-ms-flex-order: -1;
		order: -1
	}
	.col-last-xs {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-moz-order: 1;
		-ms-flex-order: 1;
		order: 1
	}
	.row--gap-no-xs {
		margin-left: 0;
		margin-right: 0
	}
	.row--gap-xsmall-xs {
		margin-left: -7px;
		margin-right: -7px
	}
	.row--gap-xsmall-xs .col {
		padding-left: 7px;
		padding-right: 7px
	}
	.row--gap-default-xs {
		margin-left: -15px;
		margin-right: -15px
	}
	.row--gap-default-xs .col {
		padding-left: 15px;
		padding-right: 15px
	}
	.row--gap-medium-xs {
		margin-left: -30px;
		margin-right: -30px
	}
	.row--gap-medium-xs .col {
		padding-left: 30px;
		padding-right: 30px
	}
	.row--gap-large-xs {
		margin-left: -45px;
		margin-right: -45px
	}
	.row--gap-no-xs .col {
		padding-left: 0;
		padding-right: 0
	}
	.row--gap-large-xs .col {
		padding-left: 45px;
		padding-right: 45px
	}
}

@media (max-width: 376px) {
	.col-xxs-1 {
		flex-basis: 4.16667%;
		max-width: 4.16667%;
		width: 4.16667%
	}
	.col-offset-xxs-1 {
		margin-left: 4.16667%
	}
	.col-xxs-2 {
		flex-basis: 8.33333%;
		max-width: 8.33333%;
		width: 8.33333%
	}
	.col-offset-xxs-2 {
		margin-left: 8.33333%
	}
	.col-xxs-3 {
		flex-basis: 12.5%;
		max-width: 12.5%;
		width: 12.5%
	}
	.col-offset-xxs-3 {
		margin-left: 12.5%
	}
	.col-xxs-4 {
		flex-basis: 16.66667%;
		max-width: 16.66667%;
		width: 16.66667%
	}
	.col-offset-xxs-4 {
		margin-left: 16.66667%
	}
	.col-xxs-5 {
		flex-basis: 20.83333%;
		max-width: 20.83333%;
		width: 20.83333%
	}
	.col-offset-xxs-5 {
		margin-left: 20.83333%
	}
	.col-xxs-6 {
		flex-basis: 25%;
		max-width: 25%;
		width: 25%
	}
	.col-offset-xxs-6 {
		margin-left: 25%
	}
	.col-xxs-7 {
		flex-basis: 29.16667%;
		max-width: 29.16667%;
		width: 29.16667%
	}
	.col-offset-xxs-7 {
		margin-left: 29.16667%
	}
	.col-xxs-8 {
		flex-basis: 33.33333%;
		max-width: 33.33333%;
		width: 33.33333%
	}
	.col-offset-xxs-8 {
		margin-left: 33.33333%
	}
	.col-xxs-9 {
		flex-basis: 37.5%;
		max-width: 37.5%;
		width: 37.5%
	}
	.col-offset-xxs-9 {
		margin-left: 37.5%
	}
	.col-xxs-10 {
		flex-basis: 41.66667%;
		max-width: 41.66667%;
		width: 41.66667%
	}
	.col-offset-xxs-10 {
		margin-left: 41.66667%
	}
	.col-xxs-11 {
		flex-basis: 45.83333%;
		max-width: 45.83333%;
		width: 45.83333%
	}
	.col-offset-xxs-11 {
		margin-left: 45.83333%
	}
	.col-xxs-12 {
		flex-basis: 50%;
		max-width: 50%;
		width: 50%
	}
	.col-offset-xxs-12 {
		margin-left: 50%
	}
	.col-xxs-13 {
		flex-basis: 54.16667%;
		max-width: 54.16667%;
		width: 54.16667%
	}
	.col-offset-xxs-13 {
		margin-left: 54.16667%
	}
	.col-xxs-14 {
		flex-basis: 58.33333%;
		max-width: 58.33333%;
		width: 58.33333%
	}
	.col-offset-xxs-14 {
		margin-left: 58.33333%
	}
	.col-xxs-15 {
		flex-basis: 62.5%;
		max-width: 62.5%;
		width: 62.5%
	}
	.col-offset-xxs-15 {
		margin-left: 62.5%
	}
	.col-xxs-16 {
		flex-basis: 66.66667%;
		max-width: 66.66667%;
		width: 66.66667%
	}
	.col-offset-xxs-16 {
		margin-left: 66.66667%
	}
	.col-xxs-17 {
		flex-basis: 70.83333%;
		max-width: 70.83333%;
		width: 70.83333%
	}
	.col-offset-xxs-17 {
		margin-left: 70.83333%
	}
	.col-xxs-18 {
		flex-basis: 75%;
		max-width: 75%;
		width: 75%
	}
	.col-offset-xxs-18 {
		margin-left: 75%
	}
	.col-xxs-19 {
		flex-basis: 79.16667%;
		max-width: 79.16667%;
		width: 79.16667%
	}
	.col-offset-xxs-19 {
		margin-left: 79.16667%
	}
	.col-xxs-20 {
		flex-basis: 83.33333%;
		max-width: 83.33333%;
		width: 83.33333%
	}
	.col-offset-xxs-20 {
		margin-left: 83.33333%
	}
	.col-xxs-21 {
		flex-basis: 87.5%;
		max-width: 87.5%;
		width: 87.5%
	}
	.col-offset-xxs-21 {
		margin-left: 87.5%
	}
	.col-xxs-22 {
		flex-basis: 91.66667%;
		max-width: 91.66667%;
		width: 91.66667%
	}
	.col-offset-xxs-22 {
		margin-left: 91.66667%
	}
	.col-xxs-23 {
		flex-basis: 95.83333%;
		max-width: 95.83333%;
		width: 95.83333%
	}
	.col-offset-xxs-23 {
		margin-left: 95.83333%
	}
	.col-xxs-24 {
		flex-basis: 100%;
		max-width: 100%;
		width: 100%
	}
	.col-offset-xxs-24 {
		margin-left: 100%
	}
	.a-i-start-xxs {
		-webkit-box-align: start;
		-ms-flex-align: start;
		-webkit-align-items: flex-start;
		-moz-align-items: flex-start;
		align-items: flex-start
	}
	.a-i-center-xxs {
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		-moz-align-items: center;
		align-items: center
	}
	.a-i-end-xxs {
		-webkit-box-align: end;
		-ms-flex-align: end;
		-webkit-align-items: flex-end;
		-moz-align-items: flex-end;
		align-items: flex-end
	}
	.j-c-start-xxs {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		-webkit-justify-content: flex-start;
		-moz-justify-content: flex-start;
		justify-content: flex-start
	}
	.j-c-center-xxs {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		-moz-justify-content: center;
		justify-content: center;
		text-align: center
	}
	.j-c-end-xxs {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		-webkit-justify-content: flex-end;
		-moz-justify-content: flex-end;
		justify-content: flex-end;
		text-align: right
	}
	.j-c-around-xxs {
		-ms-flex-pack: distribute;
		-webkit-justify-content: space-around;
		-moz-justify-content: space-around;
		justify-content: space-around
	}
	.j-c-between-xxs {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		-webkit-justify-content: space-between;
		-moz-justify-content: space-between;
		justify-content: space-between
	}
	.a-s-start-xxs {
		-webkit-align-self: flex-start;
		-moz-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start
	}
	.a-s-center-xxs {
		-webkit-align-self: center;
		-moz-align-self: center;
		-ms-flex-item-align: center;
		align-self: center
	}
	.a-s-end-xxs {
		-webkit-align-self: flex-end;
		-moz-align-self: flex-end;
		-ms-flex-item-align: end;
		align-self: flex-end
	}
	.col-first-xxs {
		order: -1;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-moz-order: -1;
		-ms-flex-order: -1;
		order: -1
	}
	.col-last-xxs {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-moz-order: 1;
		-ms-flex-order: 1;
		order: 1
	}
	.row--gap-no-xxs {
		margin-left: 0;
		margin-right: 0
	}
	.row--gap-xsmall-xxs {
		margin-left: -7px;
		margin-right: -7px
	}
	.row--gap-xsmall-xxs .col {
		padding-left: 7px;
		padding-right: 7px
	}
	.row--gap-default-xxs {
		margin-left: -15px;
		margin-right: -15px
	}
	.row--gap-default-xxs .col {
		padding-left: 15px;
		padding-right: 15px
	}
	.row--gap-medium-xxs {
		margin-left: -30px;
		margin-right: -30px
	}
	.row--gap-medium-xxs .col {
		padding-left: 30px;
		padding-right: 30px
	}
	.row--gap-large-xxs {
		margin-left: -45px;
		margin-right: -45px
	}
	.row--gap-no-xxs .col {
		padding-left: 0;
		padding-right: 0
	}
	.row--gap-large-xxs .col {
		padding-left: 45px;
		padding-right: 45px
	}
}

.animate-in-view .animate-in-view-elem {
	opacity: 0;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	-ms-transition: all 1s ease;
	-webkit-transition: all 1s ease;
	transition: all 1s ease
}

.animate-in-view.is-in-view .animate-in-view-elem,
.animate-in-view.is-in-view.animate-in-view-elem {
	opacity: 1
}

.a1now-header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 120
}

.a1now-header:before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 87px;
	background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.25) 0%, rgba(255, 255, 255, 0) 100%);
	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.25) 0%, rgba(255, 255, 255, 0) 100%);
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(255, 255, 255, 0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000', endColorstr='#00ffffff', GradientType=0)
}

@media (max-width: 581px) {
	.a1now-header:before {
		height: 60px
	}
}

.a1now-header__logo {
	display: block;
	width: 109px;
	height: 97px;
	background: white
}

@media (max-width: 767px) {
	.a1now-header__logo {
		width: 78px;
		height: 70px
	}
}

.a1now-mobileoverlay-trigger {
	display: inline-block;
	width: 30px;
	padding: 7px 0;
	margin-right: 5px
}

.a1now-mobileoverlay-trigger-inner,
.a1now-mobileoverlay-trigger-inner:after,
.a1now-mobileoverlay-trigger-inner:before {
	width: 100%;
	height: 3px;
	background: #fff
}

.a1now-mobileoverlay-trigger-inner {
	position: relative
}

.a1now-mobileoverlay-trigger-inner:before {
	content: "";
	position: absolute;
	left: 0;
	bottom: -7px
}

.a1now-mobileoverlay-trigger-inner:after {
	content: "";
	position: absolute;
	left: 0;
	top: -7px
}

.a1now-main > .page {
	flex-grow:1;
}

.a1now-main > .page > .container.container--small{
	max-width:500px;
	margin:50px auto;
	position: relative;
	overflow:hidden;
}

.a1now-footer {
	padding: 30px 0;
	background: #e8e8e8;
	line-height: 1;
	font-size: 14px;
	font-size: .875rem;
	flex-grow:0;
}

@media (max-width: 581px) {
	.a1now-footer {
		font-size: 12px;
		font-size: .75rem;
		line-height: 1.5;
	}
}

.a1now-footer__copyright {
	color: grey;
	text-align: right
}

@media (max-width: 581px) {
	.a1now-footer__copyright {
		margin-top: 15px;
		text-align: center
	}
}

.a1now-site {
	height: 100%
}

.a1now-main {
	height: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-direction: normal;
	-webkit-box-orient: vertical;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column
}

.a1now-page-hero {
	position: relative;
	min-height: 413px;
	padding: 200px 0 50px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	-webkit-align-items: flex-end;
	-moz-align-items: flex-end;
	align-items: flex-end
}

@media (max-width: 581px) {
	.a1now-page-hero {
		min-height: 250px;
		padding-bottom: 25px;
		background-color: rgba(255, 255, 255, 0.8);
		background-size: cover;
		background-position: center center;
		background-repeat:no-repeat;
	}
}

.a1now-page-hero__text {
	position: relative;
	z-index: 2;
	color: #fff
}

.a1now-page-hero__title {
	font-weight: 700
}

.a1now-page-hero:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.25)
}

.a1now-content-area {
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-moz-box-flex: 1;
	-moz-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto
}

.a1now-content-area--page {
	margin: 50px 0
}

@media (max-width: 581px) {
	.a1now-content-area--page {
		margin: 30px 0
	}
}

.a1now-page__content {
	padding-top: 10px;
	margin-bottom: 70px;
	color: #707070
}

@media (max-width: 581px) {
	.a1now-page__content {
		margin-bottom: 50px
	}
}

.a1now-accordion {
	margin-bottom: 70px
}

@media (max-width: 581px) {
	.a1now-accordion {
		margin-bottom: 50px
	}
}


.a1now-accordion__item-header {
	position: relative;
	border-bottom: 1px solid #c6c6c6
}

.a1now-accordion__item-header>a {
	display: block;
	padding: 20px 45px 20px 0;
	color: #000;
	font-size: 20px;
	font-size: 1.25rem
}

.a1now-accordion__item-header>a:hover {
	color: #b10000
}

@media (max-width: 581px) {
	.a1now-accordion__item-header>a {
		padding-right: 30px;
		font-size: 18px;
		font-size: 1.125rem
	}
}

.a1now-accordion__item-header-icon {
	position: absolute;
	top: 50%;
	right: 0;
	display: block;
	width: 30px;
	height: 30px;
	margin-top: -15px;
	-moz-transition: all 0.15s ease-out;
	-o-transition: all 0.15s ease-out;
	-ms-transition: all 0.15s ease-out;
	-webkit-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out
}

@media (max-width: 581px) {
	.a1now-accordion__item-header-icon {
		width: 20px;
		height: 20px;
		margin-top: -10px
	}
}

.a1now-accordion__item-header-icon:before,
.a1now-accordion__item-header-icon:after {
	content: '';
	position: absolute;
	top: 0;
	left: 13px;
	width: 3px;
	height: 100%;
	background: #e42313;
	-moz-transition: background 0.3s ease-out;
	-o-transition: background 0.3s ease-out;
	-ms-transition: background 0.3s ease-out;
	-webkit-transition: background 0.3s ease-out;
	transition: background 0.3s ease-out
}

@media (max-width: 581px) {
	.a1now-accordion__item-header-icon:before,
	.a1now-accordion__item-header-icon:after {
		left: 8px
	}
}

.a1now-accordion__item-header-icon:after {
	top: 13px;
	left: 0;
	width: 100%;
	height: 3px
}

@media (max-width: 581px) {
	.a1now-accordion__item-header-icon:after {
		top: 8px
	}
}

.a1now-accordion__item.is-expanded .a1now-accordion__item-header-icon {
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg)
}

.a1now-accordion__item-header:hover .a1now-accordion__item-header-icon:before,
.a1now-accordion__item-header:hover .a1now-accordion__item-header-icon:after {
	background: #b10000
}

.a1now-accordion__item-body {
	max-height:0;
	overflow:hidden;
	
	-webkit-transition: max-height .6s;
    -moz-transition: max-height .6s;
    -o-transition: max-height .6s;
	transition:max-height .6s;
}

.a1now-accordion__item-contents {
	padding: 30px 0;
	color: #707070
}


.a1now-accordion__item.is-expanded .a1now-accordion__item-body{
	max-height:4000px;
	border-bottom: 1px solid #c6c6c6;
}
@media (max-width: 581px) {
	.a1now-accordion__item.is-expanded .a1now-accordion__item-body{
		border-bottom: 1px solid #c6c6c6;
	}
}



.a1now-home-parallax {
	position: relative;
	min-height: 100%;
	padding: 200px 0 50px;
	background-size: cover;
	background-position: center center;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	-webkit-align-items: flex-end;
	-moz-align-items: flex-end;
	align-items: flex-end
}

@media (max-width: 581px) {
	.a1now-home-parallax {
		padding-bottom: 25px
	}
}

.animate-in-view .a1now-home-start__text {
	-moz-transform: translateY(-20px);
	-o-transform: translateY(-20px);
	-ms-transform: translateY(-20px);
	-webkit-transform: translateY(-20px);
	transform: translateY(-20px);
	-moz-transition: all 0.6s ease;
	-o-transition: all 0.6s ease;
	-ms-transition: all 0.6s ease;
	-webkit-transition: all 0.6s ease;
	transition: all 0.6s ease
}

.animate-in-view.is-in-view .a1now-home-start__text {
	-moz-transform: translateY(0);
	-o-transform: translateY(0);
	-ms-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

.a1now-home-start__title {
	max-width: 100%;
	margin-bottom: .15em;
	color: #ffffff;
	font-weight: 700;
	font-size: 60px;
	font-size: 4rem;
	line-height: 75px;
	line-height: 5rem
}

@media (max-width: 899px) {
	.a1now-home-start__title {
		max-width: 100%;
		font-size: 45px;
		font-size: 3rem;
		line-height: 50px;
		line-height: 3.375rem;
	}
}

@media (max-width: 767px) {
	.a1now-home-start__title {
		max-width: none;
		font-size: 45px;
		font-size: 3rem;
		line-height: 50px;
		line-height: 3.375rem;
	}
}

@media (max-width: 581px) {
	.a1now-home-start__title {
		font-size: 33px;
		font-size: 2.2rem;
		line-height: 33px;
		line-height: 2.2rem
	}
}

.a1now-home-start__subtitle {
	margin-bottom: .75em;
	color: #ffffff;
	font-size: 22px;
	font-size: 1.375rem
}

@media (max-width: 581px) {
	.a1now-home-start__subtitle {
		font-size: 15px;
		font-size: 1rem
	}
}

.a1now-home-start__hint {
	margin-top: 1.5em;
	color: #ffffff;
	font-weight: 700;
	font-size: 14px;
	font-size: .875rem
}

@media (max-width: 581px) {
	.a1now-home-start__hint {
		font-size: 12px;
		font-size: .75rem
	}
}

.a1now-home-start__hint-icon {
	display: inline-block;
	width: 18px;
	margin-left: 4px;
	vertical-align: text-bottom
}

.a1now-home-yt {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 290px;
	padding: 16px 25px 16px 31px;
	background: rgba(0, 0, 0, 0.7)
}

@media (max-width: 767px) {
	.a1now-home-yt {
		display: none
	}
}

.a1now-home-yt__title {
	margin-bottom: 10px;
	color: lightgrey;
	font-size: 16px;
	font-size: 1rem
}

.a1now-home-yt__thumb>a {
	display: block;
	position: relative;
	width: 234px
}

.a1now-home-yt__thumb>a:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5)
}

.a1now-home-yt__thumb>a:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 0;
	height: 0;
	margin-top: -24px;
	margin-left: -18px;
	border-style: solid;
	border-width: 18px 0 18px 28px;
	border-color: transparent transparent transparent #ffffff;
	-moz-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	-ms-transition: all 0.2s ease-out;
	-webkit-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out
}

.a1now-home-yt__thumb>a:hover:after {
	-moz-transform: scale(1.08);
	-o-transform: scale(1.08);
	-ms-transform: scale(1.08);
	-webkit-transform: scale(1.08);
	transform: scale(1.08)
}

.a1now-home-yt__thumb>a>img {
	display: block
}

.a1now-home-yt__subtitle {
	display: block;
	margin-top: 14px;
	font-family: 'A1 Serif', sans-serif;
	color: #ffffff;
	font-weight: 700;
	font-size: 18px;
	font-size: 1.125rem
}

.a1now-home-yt__subtitle>[class^='a1now-icon-'] {
	margin-left: 6px;
	color: #e42313;
	font-size: 11px;
	font-size: .6875rem;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out
}

.a1now-home-yt__subtitle:hover>[class^='a1now-icon-'],
.a1now-home-yt__subtitle:focus>[class^='a1now-icon-'] {
	color: #b10000
}

.a1now-home-features {
	padding: 5px 0 50px
}

@media (max-width: 581px) {
	.a1now-home-features {
		padding-bottom: 35px
	}
}

.a1now-home-features__overview {
	position: relative;
	border-bottom: 1px solid rgba(112, 112, 112, 0.5)
}

.a1now-home-features__overview-inner {
	position: relative
}

.a1now-home_features__overview-one-feature-trigger {
	display: block;
	position: relative;
	padding: 25px 0 23px;
	text-align: center;
	opacity: .3;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s
}

@media (max-width: 581px) {
	.a1now-home_features__overview-one-feature-trigger {
		padding: 19px 0 17px
	}
}

.a1now-home-features__overview-indicator {
	position: absolute;
	bottom: -2px;
	left: 0;
	width: 0;
	height: 4px;
	background: #6693ac;
	-moz-transition: all 0.15s ease-out;
	-o-transition: all 0.15s ease-out;
	-ms-transition: all 0.15s ease-out;
	-webkit-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out
}

.a1now-home_features__overview-one-feature-trigger:hover,
.a1now-home_features__overview-one-feature-trigger.is-active {
	opacity: 1
}

.a1now-home_features__one-feature-image {
	height: 50px
}

@media (max-width: 581px) {
	.a1now-home_features__one-feature-image {
		height: 38px
	}
}

.a1now-home_features__one-feature-title {
	color: #333;
	font-size: 12px;
	font-size: .75rem
}

@media (max-width: 581px) {
	.a1now-home_features__one-feature-title {
		display: none
	}
}

.a1now-home_features__overview-one-feature-trigger.is-active .a1now-home_features__one-feature-title {
	font-weight: 700
}

.a1now-home-features__content {
	position: relative;
}
@media (min-width: 581px) {
	.a1now-home-features__content {
		transition: .5s;
	}
}
.a1now-home_features__content-one-feature {
	display: none;
	padding: 94px 15px 76px;
	text-align: center;
	min-height: 360px;
}

@media (max-width: 581px) {
	.a1now-home_features__content-one-feature {
		padding: 52px 15px 30px
	}
}

.a1now-home_features__content-one-feature.is-initialy-loaded {
	display: block
}

.a1now-home_features__content-one-feature__title {
	margin-bottom: .67em
}

.a1now-home_features__content-one-feature__content {
	width: 400px;
	margin: 0 auto
}

@media (max-width: 479px) {
	.a1now-home_features__content-one-feature__content {
		width: auto
	}
}

.a1now-home-logo-carousel {
	height: 154px;
	padding: 55px 0;
	background-color: #fafafa;
	overflow: hidden
}

@media (max-width: 581px) {
	.a1now-home-logo-carousel {
		height: 98px;
		padding: 31px 0
	}
}

.a1now-home-logo-carousel .owl-stage {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	align-items: center
}

.a1now-home-logo-slide {
	margin: 0 15px
}

.a1now-home-logo-slide img {
	width: auto !important;
	max-width: 64px;
	max-height: 44px
}

@media (max-width: 581px) {
	.a1now-home-logo-slide img {
		max-width: 52px;
		max-height: 36px
	}
}

.a1now-home-teasers {
	overflow: hidden
}

.a1now-home-teasers__top-img {
	height: 387px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center
}

@media (max-width: 581px) {
	.a1now-home-teasers__top-img {
		height: 242px
	}
}

.a1now-home-teasers__content {
	padding: 64px 0 213px;
	text-align: right
}

@media (max-width: 899px) {
	.a1now-home-teasers__content {
		padding: 64px 0 150px;
		text-align: center
	}
}

@media (max-width: 581px) {
	.a1now-home-teasers__content {
		padding: 64px 0 99px
	}
}

.a1now-home-teasers__content-title {
	margin-bottom: .3125em
}

.a1now-home-teasers__content-subtitle {
	max-width: 75%;
	margin-left: auto
}

@media (max-width: 899px) {
	.a1now-home-teasers__content-subtitle {
		max-width: none
	}
}

.a1now-home-teasers__right-img {
	position: relative;
	margin-top: -100px;
	text-align: right
}

@media (max-width: 1279px) {
	.a1now-home-teasers__right-img {
		margin-right: 50px
	}
}

@media (max-width: 899px) {
	.a1now-home-teasers__right-img {
		margin-top: -150px;
		margin-bottom: 72px;
		margin-left: 110px
	}
}

@media (max-width: 581px) {
	.a1now-home-teasers__right-img {
		margin-right: 10px;
		margin-left: 78px
	}
}

@media (max-width: 479px) {
	.a1now-home-teasers__right-img {
		margin-left: 65px
	}
}

@media (max-width: 376px) {
	.a1now-home-teasers__right-img {
		margin-left: 50px
	}
}

.a1now-home-teasers__right-img-desc {
	position: absolute;
	left: calc(100% + 15px);
	top: 70px;
	width: 200px;
	color: #767676;
	text-align: right;
	font-size: 10px;
	font-size: .625rem
}

@media (max-width: 899px) {
	.a1now-home-teasers__right-img-desc {
		width: 160px;
		top: 110px;
		font-size: 8px;
		font-size: .5rem
	}
}

@media (max-width: 581px) {
	.a1now-home-teasers__right-img-desc {
		top: 100px;
		left: calc(100% + 8px);
		line-height: 1.1;
		font-size: 4px;
		font-size: .25rem
	}
}

.a1now-home-teasers__right-img-desc-inner {
	-moz-transform: rotate(-90deg) translateX(-100%);
	-o-transform: rotate(-90deg) translateX(-100%);
	-ms-transform: rotate(-90deg) translateX(-100%);
	-webkit-transform: rotate(-90deg) translateX(-100%);
	transform: rotate(-90deg) translateX(-100%);
	transform-origin: left top
}

.animate-in-view .a1now-home-teasers__content-title {
	-moz-transform: translateY(40px);
	-o-transform: translateY(40px);
	-ms-transform: translateY(40px);
	-webkit-transform: translateY(40px);
	transform: translateY(40px)
}

.animate-in-view .a1now-home-teasers__content-subtitle {
	-moz-transform: translateY(40px);
	-o-transform: translateY(40px);
	-ms-transform: translateY(40px);
	-webkit-transform: translateY(40px);
	transform: translateY(40px);
	-moz-transition-delay: 0.1s;
	-o-transition-delay: 0.1s;
	-ms-transition-delay: 0.1s;
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s
}

.animate-in-view.is-in-view .a1now-home-teasers__content-title,
.animate-in-view.is-in-view .a1now-home-teasers__content-subtitle {
	-moz-transform: translateY(0);
	-o-transform: translateY(0);
	-ms-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

.animate-in-view .a1now-home-teasers__right-img {
	opacity: 0;
	-moz-transform: translateY(40px);
	-o-transform: translateY(40px);
	-ms-transform: translateY(40px);
	-webkit-transform: translateY(40px);
	transform: translateY(40px);
	-moz-transition-delay: 0.15s;
	-o-transition-delay: 0.15s;
	-ms-transition-delay: 0.15s;
	-webkit-transition-delay: 0.15s;
	transition-delay: 0.15s
}

.animate-in-view.is-in-view .a1now-home-teasers__right-img {
	opacity: 1;
	-moz-transform: translateX(0);
	-o-transform: translateX(0);
	-ms-transform: translateX(0);
	-webkit-transform: translateX(0);
	transform: translateX(0)
}

.a1now-home-poster-carousel {
	padding: 15px 0;
	background-color: #F0F0F0;
	border-top: 1px solid #eaeaea;
	border-bottom: 1px solid #eaeaea
}

.a1now-home-poster-slide {
	margin: 0 7px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16)
}

.a1now-home-poster-slide img {
	width: 88px
}

.a1now-home-devices {
	padding: 72px 0 94px
}

@media (max-width: 581px) {
	.a1now-home-devices {
		padding: 97px 0 0
	}
}

.a1now-home-devices__title {
	margin-bottom: 72px;
	text-align: center;
	font-size: 42px;
	font-size: 2.625rem
}

@media (max-width: 899px) {
	.a1now-home-devices__title {
		font-size: 36px;
		font-size: 2.25rem
	}
}

@media (max-width: 581px) {
	.a1now-home-devices__title {
		margin-bottom: 14px;
		font-size: 28px;
		font-size: 1.75rem
	}
}

.a1now-home-devices__one-device {
	text-align: center
}

@media (max-width: 581px) {
	.a1now-home-devices__one-device {
		text-align: left
	}
}

@media (max-width: 581px) {
	.a1now-home-devices__one-device-inner {
		padding-top: 30px;
		padding-bottom: 25px;
		border-bottom: 1px solid #c6c6c6;
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flexbox;
		display: flex
	}
}

@media (max-width: 581px) {
	.a1now-home-devices__one-device:last-child .a1now-home-devices__one-device-inner {
		border-bottom: none
	}
}

@media (max-width: 581px) {
	.a1now-home-devices__one-device-icon {
		text-align: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-moz-box-flex: 1;
		-moz-flex: 1;
		-ms-flex: 1;
		flex: 1
	}
}

.a1now-home-devices__one-device-icon img {
	height: 60px
}

@media (max-width: 581px) {
	.a1now-home-devices__one-device-icon img {
		height: 36px
	}
}

@media (max-width: 581px) {
	.a1now-home-devices__one-device-info {
		-webkit-box-flex: 3;
		-webkit-flex: 3;
		-moz-box-flex: 3;
		-moz-flex: 3;
		-ms-flex: 3;
		flex: 3
	}
}

.a1now-home-devices__one-device-title {
	font-weight: 100;
	font-size: 26px;
	font-size: 1.625rem
}

@media (max-width: 581px) {
	.a1now-home-devices__one-device-title {
		padding-left: 30px;
		font-size: 22px;
		font-size: 1.375rem
	}
}

.a1now-home-devices__one-device-title:first-child {
	margin-top: 28px
}

@media (max-width: 581px) {
	.a1now-home-devices__one-device-title:first-child {
		margin-top: 0
	}
}

.a1now-home-devices__one-device-title span {
	position: relative
}

.a1now-home-devices__one-device-title span:before {
	content: '\e904';
	position: absolute;
	top: 50%;
	left: -30px;
	font-family: 'a1now' !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	color: #e42313;
	font-size: 14px;
	font-size: .875rem;
	-moz-transform: translate(0, -50%);
	-o-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out
}

.a1now-home-devices__one-device-subtitle {
	width: 200px;
	margin: 14px auto 0;
	color: #333;
	font-size: 14px;
	font-size: .875rem
}

@media (max-width: 767px) {
	.a1now-home-devices__one-device-subtitle {
		width: auto
	}
}

.animate-in-view .a1now-home-devices__title {
	-moz-transform: translateY(20px);
	-o-transform: translateY(20px);
	-ms-transform: translateY(20px);
	-webkit-transform: translateY(20px);
	transform: translateY(20px);
	-moz-transition-delay: 0.1s;
	-o-transition-delay: 0.1s;
	-ms-transition-delay: 0.1s;
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s
}

.animate-in-view.is-in-view .a1now-home-devices__title {
	-moz-transform: translateY(0);
	-o-transform: translateY(0);
	-ms-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

.animate-in-view .a1now-home-devices__one-device {
	-moz-transform: translateY(20px);
	-o-transform: translateY(20px);
	-ms-transform: translateY(20px);
	-webkit-transform: translateY(20px);
	transform: translateY(20px);
	-moz-transition-delay: 0.2s;
	-o-transition-delay: 0.2s;
	-ms-transition-delay: 0.2s;
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s
}

.animate-in-view .a1now-home-devices__one-device:nth-child(2) {
	-moz-transform: translateY(20px);
	-o-transform: translateY(20px);
	-ms-transform: translateY(20px);
	-webkit-transform: translateY(20px);
	transform: translateY(20px);
	-moz-transition-delay: 0.3s;
	-o-transition-delay: 0.3s;
	-ms-transition-delay: 0.3s;
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s
}

.animate-in-view .a1now-home-devices__one-device:nth-child(3) {
	-moz-transform: translateY(20px);
	-o-transform: translateY(20px);
	-ms-transform: translateY(20px);
	-webkit-transform: translateY(20px);
	transform: translateY(20px);
	-moz-transition-delay: 0.4s;
	-o-transition-delay: 0.4s;
	-ms-transition-delay: 0.4s;
	-webkit-transition-delay: 0.4s;
	transition-delay: 0.4s
}

.animate-in-view .a1now-home-devices__one-device:nth-child(4) {
	-moz-transform: translateY(20px);
	-o-transform: translateY(20px);
	-ms-transform: translateY(20px);
	-webkit-transform: translateY(20px);
	transform: translateY(20px);
	-moz-transition-delay: 0.5s;
	-o-transition-delay: 0.5s;
	-ms-transition-delay: 0.5s;
	-webkit-transition-delay: 0.5s;
	transition-delay: 0.5s
}

.animate-in-view.is-in-view .a1now-home-devices__one-device {
	-moz-transform: translateY(0);
	-o-transform: translateY(0);
	-ms-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

.a1now-home-packages {
	padding: 45px 0 50px;
	background: #efefef
}

@media (max-width: 1023px) {
	.a1now-home-packages {
		padding: 90px 0 72px
	}
}

.a1now-home-packages__title-mobile {
	padding: 0 15px;
	text-align: center
}

@media (max-width: 767px) {
	.a1now-home-packages.a1now-home-packages-count-1>.container {
		padding-left: 0;
		padding-right: 0
	}
}

@media (max-width: 899px) {
	.a1now-home-packages.a1now-home-packages-count-2>.container {
		padding-left: 0;
		padding-right: 0
	}
}

@media (max-width: 1199px) {
	.a1now-home-packages.a1now-home-packages-count-3>.container {
		padding-left: 0;
		padding-right: 0
	}
}

.a1now-home-packages__description-cell {
	height: 72px;
	padding: 16px;
	padding-right: 10px;
	margin-right: -10px;
	border-bottom: 1px solid #d7d7d7;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	align-items: center
}

.a1now-home-packages__description-cell:nth-child(2) {
	border-top: 1px solid #d7d7d7
}

.a1now-home-packages__description-header,
.a1now-home-packages__package-header {
	height: 78px
}

.a1now-home-packages__description-header {
	font-size: 28px;
	font-size: 1.75rem
}

.a1now-home-packages__description-header-inner {
	padding: 18px
}

.a1now-home-packages__description-title {
	font-family: 'A1 Serif', sans-serif
}

.a1now-home-packages__package-header-inner {
	position: relative;
	text-align: center;
	background-color: #6693ac;
	color: #fff;
	padding: 26px 0
}

.a1now-home-packages__package-title {
	font-family: 'A1 Serif', sans-serif;
	font-weight: 700;
	font-size: 16px;
	font-size: 1.067rem
}

.a1now-home-packages__package-cell:not(.a1now-home-packages__package-cell--btn) {
	height: 72px;
	padding: 0 10px;
	margin: 0 -10px;
	border-bottom: 1px solid #d7d7d7;
	text-align: center;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap
}

@media (max-width: 767px) {
	.a1now-home-packages-count-1 .a1now-home-packages__package-cell:not(.a1now-home-packages__package-cell--btn) {
		padding: 0;
		margin: 0;
		border-bottom: none
	}
}

@media (max-width: 899px) {
	.a1now-home-packages-count-2 .a1now-home-packages__package-cell:not(.a1now-home-packages__package-cell--btn) {
		padding: 0;
		margin: 0;
		border-bottom: none
	}
}

@media (max-width: 1199px) {
	.a1now-home-packages-count-3 .a1now-home-packages__package-cell:not(.a1now-home-packages__package-cell--btn) {
		padding: 0;
		margin: 0;
		border-bottom: none
	}
}

.a1now-home-packages__package-cell-inner {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-moz-box-flex: 1;
	-moz-flex: 1;
	-ms-flex: 1;
	flex: 1
}

.a1now-home-packages__package:last-child .a1now-home-packages__package-cell:not(.a1now-home-packages__package-cell--btn) {
	padding-right: 0;
	margin-right: 0
}

.a1now-home-packages-count-1 .a1now-home-packages__package--package1 {
	border-right: none
}

@media (max-width: 899px) {
	.a1now-home-packages-count-2 .a1now-home-packages__package--package1 {
		border-right: 1px solid #eaeaea
	}
}

@media (max-width: 1199px) {
	.a1now-home-packages-count-3 .a1now-home-packages__package--package1 {
		border-right: 1px solid #eaeaea
	}
}

@media (max-width: 1199px) {
	.a1now-home-packages-count-3 .a1now-home-packages__package--package3 {
		border-left: 1px solid #eaeaea
	}
}

@media (max-width: 899px) {
	.a1now-home-packages__package--package1,
	.a1now-home-packages__package--package2,
	.a1now-home-packages__package--package3 {
		border-top: 1px solid #eaeaea;
		border-bottom: 1px solid #eaeaea
	}
}

@media (max-width: 767px) {
	.a1now-home-packages__package--package1 .a1now-home-packages__package--package1 {
		border-top: 1px solid #eaeaea;
		border-bottom: 1px solid #eaeaea
	}
}

.a1now-home-packages__package-cell:first-child {
	border-top: 1px solid #d7d7d7
}

@media (max-width: 767px) {
	.a1now-home-packages-count-1 .a1now-home-packages__package-cell:first-child {
		border-top: none
	}
}

@media (max-width: 899px) {
	.a1now-home-packages-count-2 .a1now-home-packages__package-cell:first-child {
		border-top: none
	}
}

@media (max-width: 1199px) {
	.a1now-home-packages-count-3 .a1now-home-packages__package-cell:first-child {
		border-top: none
	}
}

.a1now-home-packages__package-cell:last-child {
	border-bottom: none
}

.a1now-home-packages__package-cell--btn {
	height: auto;
	padding: 20px 15px 18px
}

.a1now-home-packages__package-cell--btn .a1now-btn {
	width: 100%;
	font-size: 22px;
	font-size: 1.375rem
}

@media (max-width: 581px) {
	.a1now-home-packages__package-cell--btn .a1now-btn {
		font-size: 20px;
		font-size: 1.25rem
	}
}

.a1now-home-packages__package-cell-title {
	text-align: center;
	font-size: 16px;
	font-size: 1rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 100%;
	-moz-box-flex: 1;
	-moz-flex: 1 1 100%;
	-ms-flex: 1 1 100%;
	flex: 1 1 100%
}

@media (max-width: 581px) {
	.a1now-home-packages__package-cell-title {
		font-size: 12px;
		font-size: .75rem
	}
}

.a1now-home-packages__package-cell-text {
	width: 100%;
	color: #333;
	font-size: 26px;
	font-size: 1.625rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 100%;
	-moz-box-flex: 1;
	-moz-flex: 1 1 100%;
	-ms-flex: 1 1 100%;
	flex: 1 1 100%
}

.a1now-home-packages__package-cell-text>.a1now-icon-tick {
	color: #e42313
}

.a1now-home-packages__package-cell-text>.a1now-icon-cross {
	color: #bcbcbc
}

.a1now-home-packages__package-cell-text.bold-text {
	font-weight: bold
}

.a1now-home-packages__package-cell-text.strike-through {
	text-decoration: line-through;
	color: red
}

.a1now-home-packages__package-content {
	box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.16)
}

@media (max-width: 767px) {
	.a1now-home-packages-count-1 .a1now-home-packages__package-content {
		box-shadow: none
	}
}

@media (max-width: 899px) {
	.a1now-home-packages-count-2 .a1now-home-packages__package-content {
		box-shadow: none
	}
}

@media (max-width: 1199px) {
	.a1now-home-packages-count-3 .a1now-home-packages__package-content {
		box-shadow: none
	}
}

.a1now-home-packages__package-content-inner {
	background: #fff
}

@media (max-width: 767px) {
	.a1now-home-packages-count-1 .a1now-home-packages__package-content-inner {
		padding-top: 25px
	}
}

@media (max-width: 899px) {
	.a1now-home-packages-count-2 .a1now-home-packages__package-content-inner {
		padding-top: 25px
	}
}

@media (max-width: 1199px) {
	.a1now-home-packages-count-3 .a1now-home-packages__package-content-inner {
		padding-top: 25px
	}
}

.a1now-home-listing {
	padding-top: 72px
}

@media (max-width: 581px) {
	.a1now-home-listing {
		padding-top: 35px
	}
}

.a1now-home-listing__inner {
	padding-bottom: 76px;
	border-bottom: 1px solid #c6c6c6
}

@media (max-width: 581px) {
	.a1now-home-listing__inner {
		padding-bottom: 54px
	}
}

.a1now-home-listing__logo {
	width: 198px
}

.a1now-home-listing-title {
	padding-left: 5px;
	margin-bottom: .7em;
	font-weight: 100;
	font-size: 42px;
	font-size: 2.625rem
}

@media (max-width: 899px) {
	.a1now-home-listing-title {
		font-size: 36px;
		font-size: 2.25rem
	}
}

@media (max-width: 581px) {
	.a1now-home-listing-title {
		margin-top: 22px;
		font-size: 28px;
		font-size: 1.75rem
	}
}

.a1now-home-listing__list {
	padding-left: 30px
}

.a1now-home-listing__list-element__title {
	position: relative;
	margin-top: 20px;
	font-weight: bold;
	font-size: 18px;
	font-size: 1.125rem
}

.a1now-home-listing__list-element__title:before {
	content: '\e904';
	position: absolute;
	top: 50%;
	left: -30px;
	font-family: 'a1now' !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	color: #e42313;
	font-size: 10px;
	font-size: .625rem;
	-moz-transform: translate(0, -50%);
	-o-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out
}

.a1now-home-listing__list-element__subtitle {
	font-size: 14px;
	font-size: .875rem
}

.a1now-home-listing .a1now-btn-container {
	margin-top: 36px
}

@media (max-width: 581px) {
	.a1now-home-listing .a1now-btn-container {
		text-align: center
	}
}

.a1now-home-moviepreview {
	padding-top: 67px
}

@media (max-width: 581px) {
	.a1now-home-moviepreview {
		padding-top: 35px
	}
}

.a1now-home-moviepreview__title {
	margin-bottom: 50px;
	font-size: 42px;
	font-size: 2.625rem
}

@media (max-width: 899px) {
	.a1now-home-moviepreview__title {
		font-size: 36px;
		font-size: 2.25rem
	}
}

@media (max-width: 581px) {
	.a1now-home-moviepreview__title {
		font-size: 28px;
		font-size: 1.75rem
	}
}

.a1now-home-moviepreview__movies {
	padding-bottom: 60px;
	border-bottom: 1px solid #c6c6c6;
	overflow: hidden
}

@media (max-width: 581px) {
	.a1now-home-moviepreview__movies {
		padding-bottom: 20px
	}
}

.a1now-home-movies__one-movie {
	position: relative;
	height: 0;
	padding-bottom: 66.66666667%;
	margin-bottom: 14px;
	overflow: hidden
}

.a1now-home-movies__one-movie-container.col-6 .a1now-home-movies__one-movie {
	padding-bottom: 65.95%
}

.a1now-home-movies__one-movie__bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out
}

.a1now-home-movies__one-movie:hover .a1now-home-movies__one-movie__bg {
	-moz-transform: scale(1.05);
	-o-transform: scale(1.05);
	-ms-transform: scale(1.05);
	-webkit-transform: scale(1.05);
	transform: scale(1.05)
}

.a1now-home-movies__one-movie:hover .a1now-home-movies__one-movie-infos {
	padding-bottom: 25px
}

.a1now-home-movies__one-movie-inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}

.a1now-home-movies__one-movie-infos {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 18px 15px;
	color: white;
	font-family: 'A1 Serif', sans-serif;
	background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #000 100%);
	background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #000 100%);
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #000 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#000000', GradientType=0);
	line-height: 1;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out
}

.a1now-home-movies__one-movie-title {
	margin-bottom: .275em;
	font-weight: 700;
	font-size: 22px;
	font-size: 1.375rem
}

@media (max-width: 581px) {
	.a1now-home-movies__one-movie-title {
		font-size: 18px;
		font-size: 1.125rem
	}
}

@media (max-width: 581px) {
	.a1now-home-movies__one-movie-meta {
		font-size: 12px;
		font-size: .75rem
	}
}

.a1now-home-logo-collection {
	padding: 50px 0 100px
}

@media (max-width: 581px) {
	.a1now-home-logo-collection {
		padding: 30px 0 50px
	}
}

.a1now-home-logo-collection__logo-container {
	min-height: 96px;
	margin-bottom: 30px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex
}

@media (max-width: 767px) {
	.a1now-home-logo-collection__logo-container {
		min-height: 54px
	}
}

@media (max-width: 581px) {
	.a1now-home-logo-collection__logo-container {
		margin-bottom: 14px
	}
}

.a1now-home-logo-collection__logo {
	padding: 20px 15px;
	background: #eeeeee;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-moz-box-flex: 1;
	-moz-flex: 1;
	-ms-flex: 1;
	flex: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	justify-content: center
}

@media (max-width: 581px) {
	.a1now-home-logo-collection__logo {
		padding: 10px
	}
}

.a1now-home-logo-collection__logo>img {
	display: block;
	width: auto;
	max-width: 85px;
	max-height: 56px
}

@media (max-width: 767px) {
	.a1now-home-logo-collection__logo>img {
		max-width: 48px;
		max-height: 32px
	}
}

.a1now-mobileoverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 35px 25px 25px;
	visibility: hidden;
	background: rgba(0, 0, 0, 0.9);
	color: white;
	z-index: 200;
	transform-origin: center top;
	opacity: 0;
	overflow-y: auto;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s
}

@media (max-width: 581px) {
	.a1now-mobileoverlay {
		padding-top: 25px
	}
}
body.mobileoverlay-active{
	overflow:hidden;
}
body.mobileoverlay-active .a1now-mobileoverlay {
	visibility: visible;
	opacity: 1
}

.a1now-mobileoverlay__close {
	position: absolute;
	top: 26px;
	right: 16px;
	padding: 10px;
	background: transparent;
	border: none;
	color: white;
	font-size: 18px;
	font-size: 1.125rem
}

@media (max-width: 581px) {
	.a1now-mobileoverlay__close {
		top: 13px
	}
}

.a1now-mobileoverlay__title {
	text-align: center
}

@media (max-width: 581px) {
	.a1now-mobileoverlay__title {
		font-size: 13px;
		font-size: .8125rem
	}
}

.a1now-home-yt-mobile {
	text-align: center
}

.a1now-home-yt-mobile__title {
	margin-bottom: 10px;
	color: lightgrey;
	font-size: 17px;
	font-size: 1.0625rem
}

.a1now-home-yt-mobile__thumb {
	position: relative
}

.a1now-home-yt-mobile__thumb>a {
	display: block;
	position: relative;
	width: 234px;
	margin: 0 auto
}

.a1now-home-yt-mobile__thumb>a:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5)
}

.a1now-home-yt-mobile__thumb>a:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 0;
	height: 0;
	margin-top: -24px;
	margin-left: -18px;
	border-style: solid;
	border-width: 18px 0 18px 28px;
	border-color: transparent transparent transparent #ffffff;
	-moz-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	-ms-transition: all 0.2s ease-out;
	-webkit-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out
}

.a1now-home-yt-mobile__thumb>a:hover:after {
	-moz-transform: scale(1.08);
	-o-transform: scale(1.08);
	-ms-transform: scale(1.08);
	-webkit-transform: scale(1.08);
	transform: scale(1.08)
}

.a1now-home-yt-mobile__thumb>a>img {
	display: block
}

@font-face {
	font-family: 'a1now';
	src: url("fonts/a1now4759.eot?qogped");
	src: url("fonts/a1now4759.eot?qogped#iefix") format("embedded-opentype"), url("fonts/a1now4759.ttf?qogped") format("truetype"), url("fonts/a1now4759.woff?qogped") format("woff"), url("fonts/a1now4759.svg?qogped#a1now") format("svg");
	font-weight: normal;
	font-style: normal
}

[class^="a1now-icon-"],
[class*=" a1now-icon-"] {
	font-family: 'a1now' !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1
}

.a1now-icon-cross:before {
	content: "\e900"
}

.a1now-icon-tick:before {
	content: "\e904"
}

.a1now-icon-arrow_right:before {
	content: "\e905"
}

.a1now-icon-arrow_left:before {
	content: "\e906"
}

.font-weight-normal {
	font-weight: 400
}

.is-hidden {
	display: none
}

.is-visible {
	display: block
}

@media (max-width: 1279px) {
	.is-hidden-xxxl {
		display: none
	}
	.is-visible-xxxl {
		display: block
	}
}

@media (max-width: 1199px) {
	.is-hidden-xxl {
		display: none
	}
	.is-visible-xxl {
		display: block
	}
}

@media (max-width: 1023px) {
	.is-hidden-xl {
		display: none
	}
	.is-visible-xl {
		display: block
	}
}

@media (max-width: 899px) {
	.is-hidden-l {
		display: none
	}
	.is-visible-l {
		display: block
	}
}

@media (max-width: 767px) {
	.is-hidden-m {
		display: none
	}
	.is-visible-m {
		display: block
	}
}

@media (max-width: 581px) {
	.is-hidden-s {
		display: none
	}
	.is-visible-s {
		display: block
	}
}

@media (max-width: 479px) {
	.is-hidden-xs {
		display: none
	}
	.is-visible-xs {
		display: block
	}
}

@media (max-width: 376px) {
	.is-hidden-xxs {
		display: none
	}
	.is-visible-xxs {
		display: block
	}
}

.owl-carousel,
.owl-carousel .owl-item {
	-webkit-tap-highlight-color: transparent;
	position: relative
}

.owl-carousel {
	/*display: none;*/
	width: 100%;
	z-index: 1
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
	touch-action: manipulation;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0
}

.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
	min-height: 1px;
	float: left;
	-webkit-backface-visibility: hidden;
	-webkit-touch-callout: none;
	backface-visibility: hidden;
}

.owl-carousel .owl-item img {
	display: block;
	width: 100%
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
	display: none
}

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
	display: block
}

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
	background: 0 0;
	color: inherit;
	border: none;
	padding: 0!important;
	font: inherit
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block
}

.owl-carousel.owl-hidden {
	opacity: 0
}

.owl-carousel.owl-refresh .owl-item {
	visibility: hidden
}

.owl-carousel.owl-drag .owl-item {
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.owl-carousel.owl-grab {
	cursor: move;
	cursor: grab
}

.owl-carousel.owl-rtl {
	direction: rtl
}

.owl-carousel.owl-rtl .owl-item {
	float: right
}

.owl-carousel .animated {
	animation-duration: 1s;
	animation-fill-mode: both
}

.owl-carousel .owl-animated-in {
	z-index: 0
}

.owl-carousel .owl-animated-out {
	z-index: 1
}

.owl-carousel .fadeOut {
	animation-name: fadeOut
}

@keyframes fadeOut {
	0% {
		opacity: 1
	}
	100% {
		opacity: 0
	}
}

.owl-height {
	transition: height .5s ease-in-out
}

.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity .4s ease
}

.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
	max-height: 0
}

.owl-carousel .owl-item img.owl-lazy {
	transform-style: preserve-3d
}

.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	cursor: pointer;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transition: transform .1s ease
}

.owl-carousel .owl-video-play-icon:hover {
	-ms-transform: scale(1.3, 1.3);
	transform: scale(1.3, 1.3)
}

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
	display: none
}

.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	transition: opacity .4s ease
}

.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
	height: 100%;
	width: 100%
}



.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
	position: absolute;
	display: block;
	width: 42px;
	height: 100%;
	line-height: 0px;
	font-size: 0px;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 0;
	padding: 0;
	border: none;
	outline: none;
	background: #ffffff;
	z-index: 10;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16)
}

@media (max-width: 581px) {
	.owl-carousel .owl-nav button.owl-next,
	.owl-carousel .owl-nav button.owl-prev {
		width: 30px;
		background: rgba(255, 255, 255, 0.8)
	}
}

.owl-carousel .owl-nav button.owl-next:hover,
.owl-carousel .owl-nav button.owl-next:focus,
.owl-carousel .owl-nav button.owl-prev:hover,
.owl-carousel .owl-nav button.owl-prev:focus {
	outline: none
}

.owl-carousel .owl-nav button.owl-next:before,
.owl-carousel .owl-nav button.owl-prev:before {
	font-family: 'a1now' !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	color: #000000;
	font-size: 20px;
	font-size: 1.25rem;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out
}

.owl-carousel .owl-nav button.owl-next:hover:before,
.owl-carousel .owl-nav button.owl-prev:hover:before {
	color: #b10000
}

.owl-prev {
	left: 0
}

.owl-prev:before {
	content: '\e906'
}

.owl-next {
	right: 0
}

.owl-next:before {
	content: '\e905'
}

.owl-theme .owl-nav [class*='owl-'].disabled {
	display: none
}

.site-title,
.site-description {
	position: absolute;
	clip: rect(1px, 1px, 1px, 1px);
}

.a1now-home-poster-carousel .owl-stage-outer {
	max-width: 1400px;
	margin: 0 auto;
}

.a1now-home-poster-carousel .owl-stage-outer .owl-stage{
	width:16800px!important;	
}

.nowrap{
	white-space: nowrap;
}