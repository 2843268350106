.header-bg {
  background: #000000;
  min-height: 60px;
}
@media (max-width: 767px) {
  .header-bg {
    min-height: 70px;
  }
}

div.error {
  color: #da291c;
  font-size: 0.8em;
}
div.error p {
  margin: 0;
}
div.error.error-main {
  margin-top: -26px;
  margin-bottom: 50px;
  text-align: center;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
}

.tag-btn {
  box-sizing: border-box !important;
  display: block;
  position: relative;
  width: 100%;
  margin: 15px 0;
  padding: 10px 28px;
  border: none;
  text-align: center;
  text-decoration: none;
  font-family: 'A1 Serif', sans-serif;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 33px;
  line-height: 1.5;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.tag-btn-yes:hover {
  background-color: #da291c;
  border-color: #da291c;
}

.tag-color-red {
  color: #e42313;
}

.tag-btn:focus {
  outline: none;
}

.tag-btn--active,
.tag-btn-yes {
  background-color: #da291c;
  color: #ffffff;
}

.tag-btn--inverted {
  border: 2px solid #da291c;
  background-color: #ffffff;
  color: #da291c;
}

.tag-btn--back {
  color: #e42313;
  background-color: transparent;
  font-family: 'A1 Serif';
  font-size: 14px;
  font-size: 0.875rem;
}
.tag-btn--back .a1now-icon-arrow_left:before {
  font-size: 12px;
  font-size: 0.75rem;
}

.tag-btn--inactive {
  background-color: #d0d0d0;
  color: #ffffff;
  cursor: not-allowed;
}

.tag-btn--reset {
  background-color: #505050;
  color: #fff;
}

.tag-btn--with-arrow {
  padding-right: 43px;
}

.tag-btn--with-arrow:after {
  content: '';
  position: absolute;
  height: 0.6rem;
  width: 0.6rem;
  top: 1.075em;
  margin-left: 5px;
  border-right: 2px solid #ffffff;
  border-top: 2px solid #ffffff;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 30px;
}

.tag-btn--with-arrow.tag-btn--inverted:after {
  border-right: 2px solid #da291c;
  border-top: 2px solid #da291c;
}

#tagOverlay {
  z-index: 999;
  display: block;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.9);
}
#tagOverlay img {
  top: 50%;
  left: 50%;
  position: absolute;
  max-width: 40%;
  transform: translateX(-50%) translateY(-50%);
}

.tag-textfield {
  position: relative;
  margin-bottom: 24px;
  width: 100%;
}

.tag-textfield input {
  height: 40px;
  /* height: 2.5rem; */
  width: 100%;
  margin: 0 0 4px 0;
  padding: 0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  box-sizing: content-box;
  font-weight: 700;
  font-size: 18px;
  /* font-size: 1.125rem; */
  -moz-transition: box-shadow 0.3s, border 0.3s;
  -o-transition: box-shadow 0.3s, border 0.3s;
  -ms-transition: box-shadow 0.3s, border 0.3s;
  -webkit-transition: box-shadow 0.3s, border 0.3s;
  transition: box-shadow 0.3s, border 0.3s;
}

.tag-textfield input:focus:not([readonly]) {
  border-bottom: 1px solid #da291c;
  box-shadow: 0 1px 0 0 #da291c;
}

.tag-textfield label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  cursor: text;
  text-align: initial;
  color: #9e9e9e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: 0 100%;
  -moz-transform: translateY(12px);
  -o-transform: translateY(12px);
  -ms-transform: translateY(12px);
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
}

.tag-textfield label:after {
  display: block;
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  -moz-transition: 0.2s opacity ease-out, 0.2s color ease-out;
  -o-transition: 0.2s opacity ease-out, 0.2s color ease-out;
  -ms-transition: 0.2s opacity ease-out, 0.2s color ease-out;
  -webkit-transition: 0.2s opacity ease-out, 0.2s color ease-out;
  transition: 0.2s opacity ease-out, 0.2s color ease-out;
}

.tag-textfield.active label {
  color: #000;
  font-weight: 400;
  transform-origin: 0 0;
  -moz-transform: translateY(-20px) scale(0.8);
  -o-transform: translateY(-20px) scale(0.8);
  -ms-transform: translateY(-20px) scale(0.8);
  -webkit-transform: translateY(-20px) scale(0.8);
  transform: translateY(-20px) scale(0.8);
}
.page-login .tag-textfield label {
  -moz-transform: translateY(-20px) scale(0.8);
  -o-transform: translateY(-20px) scale(0.8);
  -ms-transform: translateY(-20px) scale(0.8);
  -webkit-transform: translateY(-20px) scale(0.8);
  transform: translateY(-20px) scale(0.8);
}

.tag-textfield.active.warning input:not([readonly]) {
  border-bottom: 1px solid red;
  box-shadow: 0 1px 0 0 red;
}

.tag-textfield:not(.prefilled) label,
.tag-textfield:not(.prefilled) .tag-textfield__warning_text {
  -moz-transition: transform 0.2s ease-out, color 0.2s ease-out;
  -o-transition: transform 0.2s ease-out, color 0.2s ease-out;
  -ms-transition: transform 0.2s ease-out, color 0.2s ease-out;
  -webkit-transition: transform 0.2s ease-out, color 0.2s ease-out;
  transition: transform 0.2s ease-out, color 0.2s ease-out;
}

.tag-textfield__warning_text {
  position: absolute;
  bottom: -1.75rem;
  left: 0;
  font-size: 14px;
  font-size: 0.875rem;
  font-family: 'A1 Sans', sans-serif;
  font-weight: bold;
  cursor: text;
  color: red;
  transform-origin: 0 100%;
  text-align: initial;
  -moz-transform: translateY(-50px) scale(0);
  -o-transform: translateY(-50px) scale(0);
  -ms-transform: translateY(-50px) scale(0);
  -webkit-transform: translateY(-50px) scale(0);
  transform: translateY(-50px) scale(0);
}

.tag-textfield.active.warning .tag-textfield__warning_text {
  -moz-transform: translateY(-12px) scale(1);
  -o-transform: translateY(-12px) scale(1);
  -ms-transform: translateY(-12px) scale(1);
  -webkit-transform: translateY(-12px) scale(1);
  transform: translateY(-12px) scale(1);
}

.tag-textfield_hide_show {
  position: absolute;
  right: 10px;
  top: 24px;
  height: 20px;
  width: 20px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0zOTk4IDI0MTMgMjQgMjQiPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6IG5vbmU7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIGlkPSJpY192aXNpYmlsaXR5X29mZl9ibGFja18yNHB4IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzk5OCAyNDEzKSI+CiAgICA8cGF0aCBpZD0iUGZhZF80MiIgZGF0YS1uYW1lPSJQZmFkIDQyIiBjbGFzcz0iY2xzLTEiIGQ9Ik0wLDBIMjRWMjRIMFpNMCwwSDI0VjI0SDBaTTAsMEgyNFYyNEgwWk0wLDBIMjRWMjRIMFoiLz4KICAgIDxwYXRoIGlkPSJQZmFkXzQzIiBkYXRhLW5hbWU9IlBmYWQgNDMiIGQ9Ik0xMiw3YTUsNSwwLDAsMSw1LDUsNC44NTMsNC44NTMsMCwwLDEtLjM2LDEuODNsMi45MiwyLjkyQTExLjgxNywxMS44MTcsMCwwLDAsMjIuOTksMTJhMTEuODI3LDExLjgyNywwLDAsMC0xMS03LjUsMTEuNjQ1LDExLjY0NSwwLDAsMC0zLjk4LjdsMi4xNiwyLjE2QTQuODUzLDQuODUzLDAsMCwxLDEyLDdaTTIsNC4yNyw0LjI4LDYuNTVsLjQ2LjQ2QTExLjgsMTEuOCwwLDAsMCwxLDEyYTExLjgyMiwxMS44MjIsMCwwLDAsMTUuMzgsNi42NmwuNDIuNDJMMTkuNzMsMjIsMjEsMjAuNzMsMy4yNywzWk03LjUzLDkuOGwxLjU1LDEuNTVBMi44MjEsMi44MjEsMCwwLDAsOSwxMmEzLDMsMCwwLDAsMywzLDIuODIxLDIuODIxLDAsMCwwLC42NS0uMDhsMS41NSwxLjU1QTQuOTY3LDQuOTY3LDAsMCwxLDcuNTMsOS44Wm00LjMxLS43OCwzLjE1LDMuMTUuMDItLjE2YTMsMywwLDAsMC0zLTNaIi8+CiAgPC9nPgo8L3N2Zz4K');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  -moz-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.tag-textfield_hide_show.show {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMy4wLjMsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGRkZGRkY7fQ0KPC9zdHlsZT4NCjxnPg0KCTxwYXRoIGQ9Ik0yMywxMmMwLDAtMi43LDcuNS0xMC44LDcuNWgtMC4zQzMuNywxOS41LDEsMTIsMSwxMnMyLjctNy41LDEwLjgtNy41aDAuM0MyMC4yLDQuNSwyMywxMiwyMywxMnoiLz4NCgk8Y2lyY2xlIGNsYXNzPSJzdDAiIGN4PSIxMiIgY3k9IjEyIiByPSI1Ii8+DQoJPGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMyIvPg0KPC9nPg0KPC9zdmc+DQo=');
}

.tag-checbkox {
  position: relative;
}

.tag-checkbox input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.tag-checkbox .tag-checkbox__span {
  position: relative;
  padding-left: 34px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 1.5;
}

.tag-space {
  margin-bottom: 40px;
}

.tag-checkbox input[type='checkbox'] + .tag-checkbox__span:before,
.tag-checkbox input[type='checkbox'] + .tag-checkbox__span:after {
  content: '';
  left: 0;
  position: absolute;
  -moz-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s,
    top 0.2s 0.1s, left 0.2s 0.1s;
  -o-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s,
    top 0.2s 0.1s, left 0.2s 0.1s;
  -ms-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s,
    top 0.2s 0.1s, left 0.2s 0.1s;
  -webkit-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s,
    top 0.2s 0.1s, left 0.2s 0.1s;
  transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s,
    left 0.2s 0.1s;
  z-index: 1;
}

.tag-checkbox input[type='checkbox']:not(:checked) + .tag-checkbox__span:before {
  top: 3px;
  left: 3px;
  width: 17px;
  height: 17px;
  background: transparent;
}

.tag-checkbox input[type='checkbox'] + .tag-checkbox__span:after {
  height: 23px;
  width: 23px;
  background-color: transparent;
  border: 1px solid #939393;
  top: 0;
  z-index: 0;
}

.tag-checkbox input[type='checkbox']:checked + .tag-checkbox__span:before {
  top: 3px;
  left: 3px;
  width: 17px;
  height: 17px;
  background: url(images/tick_white.svg) no-repeat center center;
  background-size: contain;
}

.tag-checkbox input[type='checkbox']:checked + .tag-checkbox__span:after {
  top: 0;
  border: 1px solid #da291c;
  background-color: #da291c;
  z-index: 0;
}

.text-center {
  text-align: center;
}

.container-head {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
}
.container-head img {
  width: 50px;
  height: 50px;
}

.tag-title {
  font-size: 32px;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 56px;
  text-align: center;
  color: #000;
}

.tag-title__subtitle {
  margin-top: 10px;
  text-align: center;
  font-size: 18px;
  font-size: 1.125rem;
}

.tag-content {
  text-align: center;
}

.tag-content__help {
  margin-top: 25px;
  color: #939393;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid #da291c;
  border-right: 0.25em solid transparent;
  border-radius: 50%;
  -webkit-animation: spinner-loborderading 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 999;
}
.loading .spinner-border {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -1rem 0 0 -1rem;
}

.no-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
